import React from 'react';

import typ from './index.d';
const Load: React.FC<typ.Props> = ({ customStyle, size = 'small' }) => {
  return (
    <div
      className={
        size === 'extraLarge'
          ? 'lds-ring-xl '
          : size === 'large'
            ? 'lds-ring-l'
            : 'lds-ring'
      }
      style={customStyle}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Load;
