import React from 'react';

import {
  Card,
  CardRow,
  CardRowForSvg,
  Button,
  Locality,
  MoneyIcon,
  PinIcon,
  Salary,
  ActionsRow,
  UserImg,
  Name,
  Job,
  CardColumn,
  ConsultantImg,
  MatchIcon,
  Matchs,
  OptionsIcon,
  StatusArea,
  StatusTitle,
  StatusComp,
} from './styles';
import { formatCPF, limitarString } from '../../../utils/convert';
import { UserStatusProps } from './index.d';

import typ from './index.d';

const CardUserJob: React.FC<typ.Props> = ({
  isAlternative,
  customStyle,
  client,
  onClick,
}) => {
  return (
    <Card
      isAlternative={isAlternative}
      style={customStyle}
      onClick={() => {
        if (onClick) onClick();
      }}>
      <ActionsRow>
        <CardRowForSvg>
          <StatusComp>
            <StatusArea
              status={
                client?.status === 'Disponível' ? 'Disponível' : 'indisponivel'
              }>
              <StatusTitle
                status={
                  client?.status === 'Disponível'
                    ? 'Disponível'
                    : 'indisponivel'
                }>
                {client?.status}
              </StatusTitle>
            </StatusArea>
          </StatusComp>
          <StatusArea status={'vinculado'}>
            <StatusTitle status={'vinculado'}>
              {formatCPF(client?.cpf || '')}
            </StatusTitle>
          </StatusArea>
        </CardRowForSvg>
        <CardRowForSvg>
          <Button>
            <OptionsIcon />
          </Button>
        </CardRowForSvg>
      </ActionsRow>

      <CardRow style={{ marginBottom: 10 }}>
        <CardRowForSvg>
          <UserImg
            src={client?.image || require('../../../assets/temp/temp-user.png')}
          />
          <CardColumn>
            <Name>{limitarString(client?.name || '', 21)}</Name>
            <Job>{limitarString(client?.occupation || '', 17)}</Job>
          </CardColumn>
        </CardRowForSvg>
        {client?.advisor_relation && (
          <ConsultantImg
            src={
              client?.advisor_relation.image ||
              require('../../../assets/temp/temp-user.png')
            }
          />
        )}
      </CardRow>

      <CardRowForSvg style={{ marginBottom: 25 }}>
        {client?.budget && (
          <CardRowForSvg>
            <MoneyIcon />
            <Salary>R$ {client.budget}</Salary>
          </CardRowForSvg>
        )}
      </CardRowForSvg>

      <CardRow>
        <CardRowForSvg>
          <MatchIcon />
          <Matchs>{client?.vacancy_matching_quantity} matchs</Matchs>
        </CardRowForSvg>
        {client?.local && (
          <CardRowForSvg>
            <PinIcon />
            <Locality>{client?.local}</Locality>
          </CardRowForSvg>
        )}
      </CardRow>
    </Card>
  );
};

export default CardUserJob;

const StatusUser: React.FC<UserStatusProps.status> = ({ currentStatus }) => {
  const GerateStatus = React.useCallback(() => {
    switch (currentStatus) {
      case 'Disponível':
        return (
          <StatusArea status="Disponível">
            <StatusTitle status="Disponível">Disponivel</StatusTitle>
          </StatusArea>
        );
      case 'indisponivel':
        return (
          <StatusArea status="indisponivel">
            <StatusTitle status="indisponivel">Indisponivel</StatusTitle>
          </StatusArea>
        );
      case 'vinculado':
        return (
          <StatusArea status="vinculado">
            <StatusTitle status="vinculado">Vinculado</StatusTitle>
          </StatusArea>
        );
      case 'independente':
        return (
          <StatusArea status="independente">
            <StatusTitle status="independente">Independente</StatusTitle>
          </StatusArea>
        );
      default:
        return (
          <StatusArea status="vinculado">
            <StatusTitle status="vinculado">
              {/* {formatCPF(currentStatus)} */}
            </StatusTitle>
          </StatusArea>
        );
    }
  }, []);
  return <GerateStatus />;
};
