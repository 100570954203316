import React from 'react';
import {
  BankIcon,
  HomeIcon,
  ManagersIcon,
  MenuItem,
  ProspectIcon,
  RecolocationIcon,
  RecrutamentIcon,
  Text,
  VancaciesIcon,
} from './styles';

import typ from './index.d';

const MenuItemComp: React.FC<typ.Props> = ({ title, href, active }) => {
  const ManagerIcons = React.useCallback(() => {
    switch (title) {
      case 'Home':
        return <HomeIcon />;
      case 'Vagas':
        return <VancaciesIcon />;
      case 'Recrutamento':
        return <RecrutamentIcon />;
      case 'Recolocação':
        return <RecolocationIcon />;
      case 'Consultores':
        return <ManagersIcon />;
      case 'Prospecção':
        return <ProspectIcon />;
      case 'Banco de talentos':
      default:
        return <BankIcon />;
    }
  }, []);

  return (
    <MenuItem href={href} active={active}>
      <ManagerIcons />
      <Text>{title}</Text>
    </MenuItem>
  );
};

export default MenuItemComp;
