import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import ClientHistoryProps from './index.d';

class ClientHistoryCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }
  create({
    date,
    description,
    personal_client_id,
  }: ClientHistoryProps.createParams) {
    return axiosManager<ClientHistoryProps.createReturn>({
      method: 'post',
      url: `/client-history/create`,
      auth: this.token,
      payload: {
        date,
        description,
        personal_client_id,
      },
    });
  }

  read({
    page = 1,
    per_page = 10,
    personal_client_id,
  }: ClientHistoryProps.readParams) {
    let url = `/client-history/list?page=${page}&per_page=${per_page}&personal_client_id=${personal_client_id}`;

    return axiosManager<ClientHistoryProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }

  update({
    date,
    description,
    personal_client_id,
    id,
  }: ClientHistoryProps.updateParams) {
    return axiosManager<ClientHistoryProps.updateReturn>({
      method: 'put',
      url: `/client-history/update/${id}`,
      auth: this.token,
      payload: { date, description, personal_client_id },
    });
  }

  delete({ id }: ClientHistoryProps.deleteParams) {
    return axiosManager<ClientHistoryProps.deleteReturn>({
      method: 'delete',
      url: `/client-history/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }
}

export { ClientHistoryCRUD };
