import styled from 'styled-components';
import { IconExit } from '../icons';

export const Container = styled.div`
  height: 100%;
  width: 98px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: width 350ms ease;
  padding: 32px;
  p {
    opacity: 0;
  }

  &:hover {
    width: 303px;

    p {
      opacity: 1;
    }
  }
`;

export const LogoArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
`;
export const LogoMenu = styled.img.attrs({
  src: require('../../assets/images/rx-logo.png'),
  className: 'img-responsive',
})`
  max-width: 42px;
  height: 36px;
`;
export const LogoText = styled.p`
  font-size: 17px;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: 'AlumniSans Medium';
  margin: 0px 0 0 15px;
  line-height: 14px;
`;

export const ItensArea = styled.div`
  height: 100%;
`;
export const UserArea = styled.div`
  display: flex;
  align-items: center;
`;

export const ConsultantPhoto = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
`;

export const ConsultantName = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fontFamily.poppins.regular};
  font-size: 20px;
  margin: 0px;
  min-width: max-content;
`;

export const ExitIcon = styled(IconExit).attrs(({ theme }) => ({
  width: 25,
  height: 19,
  color: '#EB4D4B66',
}))`
  fill: none;
  margin-left: 10px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.25);
    }
  }
`;
