import styled from 'styled-components';
import { IconClose } from '../../icons';

export const Container = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
`;

export const Title = styled.h3`
    font-size: 24px;
    font-family: ${({theme}) => theme.fontFamily.poppins.semiBold};
    color: ${({theme}) => theme.colors.textSecondary};
    margin: 0px;
`;

export const CloseButton = styled.button``;
export const CloseIcon = styled(IconClose).attrs(({theme}) => ({width: 19, height: 19, color:theme.colors.red}))``;
