import styled from 'styled-components';
import Button from '../../../components/button';
import { IconAdd, IconBackFull } from '../../../components/icons';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  height: 100vh;
  /* background-color: ${({ theme }) => theme.colors.grayUltraHigh} */
  background-color: #f9f9f9;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  padding: 0 0 0 55px;
`;

export const Header = styled.div`
  padding: 25px 55px 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayUltraHigh};
  margin-bottom: 45px;
`;

export const HeaderBack = styled.a`
  margin-bottom: 107px;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const AddConsultantButton = styled(Button).attrs({
  customStyle: { marginLeft: 40 },
})``;

export const HeaderTitle = styled.h2`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  font-size: 20px;
  margin: 0px;
`;

export const KamBamArea = styled.div`
  display: flex;
  overflow-x: scroll; /* Habilita o scroll horizontal */
  width: 100vw;
  white-space: nowrap;
`;
export const KamBamColumn = styled.div`
  width: 100%;
  max-width: 294px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const KamBamTitleRow = styled.div<{ backgroundColor: string }>`
  border-radius: 5px;
  width: 100%;
  padding: 11px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: 25px;
`;
export const KamBamTitle = styled.h4`
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.medium};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.white};
`;

export const KamBamCardArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
export const KamBamCard = styled.h4`
  margin: 0px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 24px 0px #00000014;
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
`;

export const LoadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

//Icons

export const AddIcon = styled(IconAdd).attrs({
  width: 24,
  height: 24,
})`
  fill: none;
  margin-right: 10px;
`;

export const BackFullIcon = styled(IconBackFull).attrs({
  width: 74,
  height: 22,
})`
  color: #8f8f8f;
  fill: none;
  transition: color 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondaryDark};
    }
  }
`;
