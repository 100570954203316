import React from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { actions } from '../../store/actions';

const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
const useAppActions = () => {
  const dispatch = useDispatch();
  return React.useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
};

export { useAppSelector, useAppActions };
