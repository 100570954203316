import React from 'react';
import {
  ArrowIcon,
  BackFullIcon,
  ButtonComp,
  Card,
  CardColumn,
  CardDescriptionLabel,
  CardDescriptionTitle,
  CardRow,
  CardRowForSvg,
  CardsColumns,
  CardSectionColumn,
  CardSectionRow,
  CardSectionTitle,
  CardSeparator,
  ClientHistoryButotn,
  ClientHistoryCard,
  ClientHistoryContent,
  ClientHistoryData,
  ClientHistoryDescription,
  ConsultantImg,
  Container,
  ContainerCards,
  Content,
  CpfIcon,
  EditIcon,
  GraduationBold,
  GraduationCard,
  GraduationIcons,
  GraduationRow,
  GraduationText,
  Header,
  HeaderBack,
  KeyWord,
  KeyWordsArea,
  Language,
  LanguageRow,
  LoadingIndicator,
  MatchCard,
  MatchIcon,
  MatchJob,
  MatchName,
  ModalSelect,
  ModalSelectContent,
  ModalValue,
  Name,
  Occupation,
  Photo,
  Responsive,
  Select,
  SelectName,
  SelectsArea,
  SvgRow,
  TrashIcon,
  UserImg,
} from './styles';
import { VancancieCRUD } from '../../../../services/requests/vancacies';
import { VancacieData } from '../../../../services/requests/vancacies/index.d';
import Load from '../../../../components/load';
import { useTheme } from 'styled-components';
import MenuBar from '../../../../components/menu-bar';
import ResponsiveContainer from '../../../../components/container';
import { useNavigate, useParams } from 'react-router-dom';
import { LanguageCRUD } from '../../../../services/requests/language';
import { GraduationCRUD } from '../../../../services/requests/graduation';
import LanguageModal from '../../../../components/modais/language';
import Button from '../../../../components/button';
import { LanguageData } from '../../../../services/requests/language/index.d';
import {
  capitalizeFirstLetter,
  formatarPhone,
  formatCPF,
  limitarString,
  transformarData,
} from '../../../../utils/convert';
import { toast } from 'react-toastify';
import GraduationModal from '../../../../components/modais/graduation';
import { GraduationData } from '../../../../services/requests/graduation/index.d';
import { LoadRow } from '../styles';
import { theme } from '../../../../global/styles';
import { MatchCRUD } from '../../../../services/requests/matchs';
import MatchCRUDProps from '../../../../services/requests/matchs/index.d';
import { PersonalClientCRUD } from '../../../../services/requests/personal-client';
import { PersonalClientViewData } from '../../../../services/requests/personal-client/index.d';
import { CONST_STATUS } from '../../../../utils/CONSTANTS';
import { ClientHistoryCRUD } from '../../../../services/requests/client_history';
import { ClientHistoryPropsData } from '../../../../services/requests/client_history/index.d';
import ClientHistoryModal from '../../../../components/modais/client-history';

const languageCRUD = new LanguageCRUD();
const graduationCRUD = new GraduationCRUD();
const personalClientCrud = new PersonalClientCRUD();
const clientHistoryCrud = new ClientHistoryCRUD();

const VisualizatePersonalClient: React.FC = () => {
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { id } = useParams();

  // Ref
  const currentPage = React.useRef(1);

  // States
  const [currentPersonalClient, setCurrentPersonalClient] =
    React.useState<PersonalClientViewData>();
  const [loading, setLoading] = React.useState(false);

  const [languages, setLanguages] = React.useState<LanguageData[]>();
  const [loadingLanguages, setLoadingLanguages] = React.useState(false);

  const [graduation, setGraduation] = React.useState<GraduationData[]>();
  const [currentGraduation, setCurrentGraduation] =
    React.useState<GraduationData>();
  const [loadingGraduation, setLoadingGraduation] = React.useState(false);

  const [showModalLanguage, setShowModalLanguage] = React.useState(false);
  const [showModalGraduation, setShowModalGraduation] = React.useState(false);
  const [showModalClientHistory, setShowModalClientHistory] =
    React.useState(false);

  const [matchs, setMatchs] = React.useState<MatchCRUDProps.readReturn>();

  const [clientHistory, setClientHistory] =
    React.useState<ClientHistoryPropsData[]>();
  const [loadingClientHistory, setLoadingClientHistory] = React.useState(false);

  const [status, setStatus] = React.useState<{
    id: number;
    color: string;
    title: string;
    textColor?: string;
  }>(STATUS[0]);
  const [showStatusModal, setShowStatusModal] = React.useState(false);

  // Callbacks
  const refreshData = React.useCallback(async (page?: number) => {
    try {
      setLoading(true);
      currentPage.current = page || 1;
      const { data, status, error } = await personalClientCrud.view({
        id: Number(id) || 0,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setCurrentPersonalClient(data.PersonalClient[0]);
        let currentStatus: any;
        STATUS.forEach(el => {
          if (el.title === data.PersonalClient[0].status) currentStatus = el;
        });
        if (currentStatus) setStatus(currentStatus);
      }
    } catch (e) {
      alert(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, []);

  const getLanguages = React.useCallback(async () => {
    try {
      setLoadingLanguages(true);
      const { data, status, error } = await languageCRUD.read({
        isVancacie: false,
        id: Number(id),
      });

      if (error) throw new Error(error.message.description);

      if (status === 200 && data?.Language) {
        setLanguages(data.Language);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoadingLanguages(false);
    }
  }, []);

  const handleDeleteLanguage = React.useCallback(async (id: number) => {
    try {
      setLoadingLanguages(true);
      const { data, status, error } = await languageCRUD.delete({
        id: id,
      });

      if (error) throw new Error(error.message.description);

      if (status === 200) {
        getLanguages();
        toast('Linguagem deletada com sucesso.');
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  const getGraduations = React.useCallback(async () => {
    try {
      setLoadingGraduation(true);
      const { data, status, error } = await graduationCRUD.read({
        isVancacie: false,
        id: Number(id),
      });

      if (error) throw new Error(error.message.description);

      if (status === 200 && data?.Graduation) {
        setGraduation(data.Graduation);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoadingGraduation(false);
    }
  }, []);

  const handleDeleteGraduations = React.useCallback(async (id: number) => {
    try {
      setLoadingGraduation(true);
      const { data, status, error } = await graduationCRUD.delete({
        id: id,
      });

      if (error) throw new Error(error.message.description);

      if (status === 200) {
        getGraduations();
        toast('Formação deletada com sucesso.');
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  const getClientHistory = React.useCallback(async (page?: number) => {
    try {
      setLoadingClientHistory(true);
      const { data, status, error } = await clientHistoryCrud.read({
        personal_client_id: Number(id),
        page,
        per_page: 20,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setClientHistory(data.ClientHistory);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoadingClientHistory(false);
    }
  }, []);

  const handleDeleteClientHistory = React.useCallback(async (id: number) => {
    try {
      setLoadingClientHistory(true);
      const { data, status, error } = await clientHistoryCrud.delete({
        id: id,
      });

      if (error) throw new Error(error.message.description);

      if (status === 200) {
        getClientHistory();
        toast('Histórico com cliente deletado com sucesso.');
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoadingClientHistory(false);
    }
  }, []);

  // Callbacks
  const handleUpdateClient = React.useCallback(async (status?: string) => {
    let currentStatus: string = '';

    if (status) {
      if (
        CONST_STATUS.findIndex(
          item => item.label.toLowerCase() === status.toLocaleLowerCase(),
        ) >= 0
      ) {
        currentStatus =
          CONST_STATUS[
            CONST_STATUS.findIndex(
              item => item.label.toLowerCase() === status.toLocaleLowerCase(),
            )
          ].keys;
      } else {
        currentStatus = status;
      }
    }

    const dataApi: any = {};
    if (currentStatus) dataApi.status = currentStatus;

    dataApi.id = id;

    try {
      const {
        data,
        status: Status,
        error,
      } = await personalClientCrud.update(dataApi);

      if (error) throw new Error(error.message.description);
      if (Status === 200 && data) {
        toast('Cliente editado com sucesso.');
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  // Effect
  React.useEffect(() => {
    refreshData();
    getLanguages();
    getGraduations();
    getClientHistory();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Recolocação" />
      <Content>
        <Responsive>
          <Header>
            <HeaderBack href="/recolocacao-profissional">
              <BackFullIcon />
            </HeaderBack>
          </Header>
          <ContainerCards>
            <CardsColumns maxWidth={380}>
              <Card>
                <CardSectionRow>
                  <CardSectionTitle size="normal">Perfil</CardSectionTitle>
                </CardSectionRow>
                <Photo
                  src={
                    false || require('../../../../assets/temp/temp-user.png')
                  }
                />
                <Name>{currentPersonalClient?.name}</Name>

                <Occupation>{currentPersonalClient?.occupation}</Occupation>

                <SelectsArea>
                  <ModalSelectContent>
                    <ModalValue
                      background={status?.color}
                      onClick={() => {
                        setShowStatusModal(true);
                      }}
                      textColor={status.textColor}>
                      {status?.title}
                      <ArrowIcon style={{ color: status.textColor }} />
                    </ModalValue>
                    {showStatusModal && (
                      <ModalSelect>
                        {STATUS?.map(el => (
                          <Select
                            onClick={() => {
                              setStatus(el);
                              handleUpdateClient(el.title);
                              setShowStatusModal(false);
                            }}>
                            <SelectName key={el.id}>{el.title}</SelectName>
                          </Select>
                        ))}
                      </ModalSelect>
                    )}
                  </ModalSelectContent>
                </SelectsArea>

                <CardSeparator />

                <CardSectionRow>
                  <CardSectionTitle size="normal">Detalhes</CardSectionTitle>

                  <ButtonComp onClick={() => navigate(`/editar-vaga/${id}`)}>
                    <EditIcon />
                  </ButtonComp>
                </CardSectionRow>

                <CardDescriptionTitle>Id da conta</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentPersonalClient?.id}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Email</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentPersonalClient?.email}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Endereço</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentPersonalClient?.local}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Cpf</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {formatCPFScreen(currentPersonalClient?.cpf || '')}
                </CardDescriptionLabel>

                {/* <CardDescriptionTitle>Telefone</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {formatarPhone(currentPersonalClient?.)}
                </CardDescriptionLabel> */}

                <CardDescriptionTitle>Sexo</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentPersonalClient?.sexual_gender}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Estado civíl</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentPersonalClient?.marital_status}
                </CardDescriptionLabel>
                <CardDescriptionTitle>Número de filhos</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentPersonalClient?.number_children}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Dados profissionais</CardDescriptionTitle>
                {currentPersonalClient?.key_words &&
                  currentPersonalClient?.key_words?.length > 0 && (
                    <KeyWordsArea>
                      {currentPersonalClient?.key_words.map((e, i) => (
                        <KeyWord>{e}</KeyWord>
                      ))}
                    </KeyWordsArea>
                  )}
              </Card>
            </CardsColumns>

            <CardsColumns>
              <Card>
                <CardSectionRow>
                  <CardSectionTitle size="normal">
                    Profissional
                  </CardSectionTitle>

                  <ButtonComp onClick={() => navigate(`/editar-vaga/${id}`)}>
                    <EditIcon />
                  </ButtonComp>
                </CardSectionRow>

                <CardSectionRow style={{ marginBottom: 17 }}>
                  <CardSectionTitle size="normal">Detalhes</CardSectionTitle>

                  <CardSectionTitle size="normal">Recolocação</CardSectionTitle>
                </CardSectionRow>

                <CardSectionRow style={{ marginBottom: 17 }}>
                  <CardSectionColumn>
                    <CardDescriptionTitle>Ocupação</CardDescriptionTitle>
                    <CardDescriptionLabel style={{ marginBottom: 0 }}>
                      {currentPersonalClient?.occupation}
                    </CardDescriptionLabel>
                  </CardSectionColumn>

                  <CardSectionColumn>
                    <CardDescriptionTitle>Ocupação</CardDescriptionTitle>
                    <CardDescriptionLabel style={{ marginBottom: 0 }}>
                      {currentPersonalClient?.replacement_office}
                    </CardDescriptionLabel>
                  </CardSectionColumn>
                </CardSectionRow>
                <CardSectionRow style={{ marginBottom: 17 }}>
                  <CardSectionColumn>
                    <CardDescriptionTitle>Salário</CardDescriptionTitle>
                    <CardDescriptionLabel style={{ marginBottom: 0 }}>
                      R$ {currentPersonalClient?.budget}
                    </CardDescriptionLabel>
                  </CardSectionColumn>

                  <CardSectionColumn style={{ alignItems: 'flex-start' }}>
                    <CardDescriptionTitle>Empresa</CardDescriptionTitle>
                    <CardDescriptionLabel style={{ marginBottom: 0 }}>
                      {currentPersonalClient?.replacement_company}
                    </CardDescriptionLabel>
                  </CardSectionColumn>
                </CardSectionRow>

                <CardSectionRow style={{ marginBottom: 17 }}>
                  <CardSectionColumn>
                    <CardDescriptionTitle>Aceita mudar</CardDescriptionTitle>
                    <CardDescriptionLabel style={{ marginBottom: 0 }}>
                      {currentPersonalClient?.moving_conditions}
                    </CardDescriptionLabel>
                  </CardSectionColumn>

                  <CardSectionColumn style={{ alignItems: 'flex-start' }}>
                    <CardDescriptionTitle>Remuneração</CardDescriptionTitle>
                    <CardDescriptionLabel style={{ marginBottom: 0 }}>
                      {currentPersonalClient?.replacement_salary}
                    </CardDescriptionLabel>
                  </CardSectionColumn>
                </CardSectionRow>
              </Card>
              <Card alternative>
                <CardSectionRow>
                  <CardSectionTitle size="normal">
                    Experiência Profissional
                  </CardSectionTitle>
                </CardSectionRow>

                <Button
                  title="Nova experiência"
                  callback={() => {
                    if (!showModalLanguage) setShowModalLanguage(e => !e);
                  }}
                />
              </Card>
            </CardsColumns>

            <CardsColumns minWidth={400}>
              <Card>
                <CardSectionRow>
                  <CardSectionTitle size="normal">Idioma</CardSectionTitle>
                </CardSectionRow>
                {loadingLanguages ? (
                  <LoadRow>
                    <LoadingIndicator
                      customStyle={{
                        width: 40,
                        height: 40,
                        color: theme.colors.secondary,
                        marginBottom: 12,
                      }}
                      size="large"
                    />
                  </LoadRow>
                ) : (
                  <>
                    {languages && languages.length > 0 ? (
                      languages?.map(e => (
                        <LanguageRow>
                          <Language>
                            {capitalizeFirstLetter(e.language_name)}
                          </Language>
                          <Language>
                            {capitalizeFirstLetter(e.language_level)}
                          </Language>
                          <ButtonComp
                            onClick={() => handleDeleteLanguage(e.id)}>
                            <TrashIcon />
                          </ButtonComp>
                        </LanguageRow>
                      ))
                    ) : (
                      <CardSectionTitle
                        style={{ marginBottom: 12 }}
                        size="normal">
                        Nenhum idioma cadastrado...
                      </CardSectionTitle>
                    )}
                  </>
                )}
                <Button
                  title="Novo Idioma"
                  callback={() => {
                    if (!showModalLanguage) setShowModalLanguage(e => !e);
                  }}
                />
              </Card>

              <Card alternative>
                <CardSectionRow>
                  <CardSectionTitle size="normal">Formação</CardSectionTitle>
                </CardSectionRow>
                {loadingGraduation ? (
                  <LoadRow>
                    <LoadingIndicator
                      customStyle={{
                        width: 40,
                        height: 40,
                        color: theme.colors.secondary,
                        marginBottom: 12,
                      }}
                      size="large"
                    />
                  </LoadRow>
                ) : (
                  <>
                    {graduation && graduation.length > 0 ? (
                      graduation?.map(e => (
                        <GraduationCard>
                          <GraduationRow>
                            <GraduationBold>
                              {e.institution_name} - {e.graduation_type}
                            </GraduationBold>
                            <GraduationIcons>
                              <ButtonComp
                                onClick={() => {
                                  setCurrentGraduation(e);
                                  setShowModalGraduation(true);
                                }}>
                                <EditIcon />
                              </ButtonComp>
                              <ButtonComp
                                onClick={() => handleDeleteGraduations(e.id)}>
                                <TrashIcon />
                              </ButtonComp>
                            </GraduationIcons>
                          </GraduationRow>
                          <GraduationText style={{ marginTop: 0 }}>
                            {e.graduation_name} | {e.graduation_time}
                          </GraduationText>
                          <GraduationText>
                            {e.graduation_description}
                          </GraduationText>
                        </GraduationCard>
                      ))
                    ) : (
                      <CardSectionTitle
                        style={{ marginBottom: 12 }}
                        size="normal">
                        Nenhuma formação cadastrada...
                      </CardSectionTitle>
                    )}
                  </>
                )}
                <Button
                  callback={() => {
                    if (!showModalGraduation) setShowModalGraduation(true);
                  }}
                  title="Nova Formação"
                />
              </Card>
            </CardsColumns>

            <CardsColumns minWidth={381}>
              <Card alternative>
                {loadingClientHistory ? (
                  <LoadRow>
                    <LoadingIndicator
                      customStyle={{
                        width: 40,
                        height: 40,
                        color: theme.colors.secondary,
                        marginBottom: 12,
                      }}
                      size="large"
                    />
                  </LoadRow>
                ) : (
                  <ClientHistoryContent>
                    {clientHistory && clientHistory?.length > 0 ? (
                      clientHistory?.map(e => (
                        <ClientHistoryCard>
                          <LanguageRow style={{ margin: 0 }}>
                            <ClientHistoryData>
                              {transformarData(e.date)}
                            </ClientHistoryData>
                            <ButtonComp
                              onClick={() => handleDeleteClientHistory(e.id)}>
                              <TrashIcon />
                            </ButtonComp>
                          </LanguageRow>
                          <ClientHistoryDescription>
                            {e.description}
                          </ClientHistoryDescription>
                        </ClientHistoryCard>
                      ))
                    ) : (
                      <CardSectionTitle
                        style={{ marginBottom: 12 }}
                        size="normal">
                        Nenhum evento cadastrado...
                      </CardSectionTitle>
                    )}
                  </ClientHistoryContent>
                )}
                <ClientHistoryButotn
                  title="Novo Evento"
                  callback={() => {
                    setShowModalClientHistory(e => !e);
                  }}
                />
              </Card>
              <Card alternative maxHeight={390}>
                <CardSectionRow>
                  <CardSectionTitle size="normal">Matchs</CardSectionTitle>
                  <SvgRow>
                    <CardSectionTitle size="normal">
                      {currentPersonalClient?.vacancy_matching_quantity} matchs
                    </CardSectionTitle>
                    <MatchIcon />
                  </SvgRow>
                </CardSectionRow>
                {loadingGraduation ? (
                  <LoadRow>
                    <LoadingIndicator
                      customStyle={{
                        width: 40,
                        height: 40,
                        color: theme.colors.secondary,
                        marginBottom: 12,
                      }}
                      size="large"
                    />
                  </LoadRow>
                ) : (
                  <>
                    {matchs?.VacancyMatch?.map(e => (
                      <MatchCard>
                        <CardRowForSvg>
                          <UserImg
                            src={
                              e.personal_client_relation.image ||
                              require('../../../../assets/temp/temp-user.png')
                            }
                          />
                          <CardColumn>
                            <MatchName>
                              {e.personal_client_relation.name}
                            </MatchName>
                            <MatchJob>
                              {e.personal_client_relation.occupation}
                            </MatchJob>
                          </CardColumn>
                        </CardRowForSvg>
                        <CardRow>
                          <CardRowForSvg>
                            <CpfIcon />
                            <MatchName>
                              {formatCPF(e.personal_client_relation.cpf)}
                            </MatchName>
                          </CardRowForSvg>
                          <ConsultantImg
                            src={
                              e.advisor_relation.image ||
                              require('../../../../assets/temp/temp-user.png')
                            }
                          />
                        </CardRow>
                      </MatchCard>
                    ))}
                  </>
                )}
              </Card>
            </CardsColumns>
          </ContainerCards>
        </Responsive>
      </Content>
      {showModalLanguage && (
        <LanguageModal
          onClose={() => {
            getLanguages();
            setShowModalLanguage(false);
          }}
          vacancyId={currentPersonalClient?.id}
          isVacancy={false}
        />
      )}
      {showModalGraduation && (
        <GraduationModal
          onClose={() => {
            setCurrentGraduation(undefined);
            getGraduations();
            setShowModalGraduation(false);
          }}
          graduationId={currentPersonalClient?.id}
          graduation={currentGraduation}
          isVacancy={false}
        />
      )}
      {showModalClientHistory && (
        <ClientHistoryModal
          personalClientId={Number(id) || 0}
          onClose={() => {
            setShowModalClientHistory(false);
            getClientHistory();
          }}
        />
      )}
    </Container>
  );
};

export default VisualizatePersonalClient;

const STATUS = [
  {
    id: 0,
    color: '#FF565685',
    textColor: '#7C0A0A',
    title: 'Indisponível',
  },
  {
    id: 1,
    color: '#ABDBCA',
    textColor: '#316F59',
    title: 'Disponível',
  },
];

const formatCPFScreen = (value: string) => {
  if (value.length > 14) return; // CPF formatado: 000.000.000-00
  value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
  value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona o primeiro ponto
  value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona o segundo ponto
  value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Adiciona o hífen antes dos últimos dois dígitos
  return value;
};
