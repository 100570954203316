import React from 'react';

import typ from './index.d';
import {
  Amount,
  AmountIcon,
  Button,
  CalendarIcon,
  CalendarRow,
  Circle,
  Contact,
  ContactSvgRow,
  ContainerIndicator,
  CpfIcon,
  CreateVacancyButton,
  Description,
  DocumentButton,
  DocumentIcon,
  EditIcon,
  InfoArea,
  InfoRow,
  InfoSvgRow,
  Line,
  MailIcon,
  Modal,
  MoneyIcon,
  Name,
  Occupation,
  PinIcon,
  RowTitle,
  Salary,
  TrashIcon,
} from './styles';
import { useNavigate } from 'react-router-dom';

const RecrutamentViewModal: React.FC<typ.Props> = ({
  onClose,
  client,
  showDeleteModal,
  showEditModal,
}) => {
  const navigation = useNavigate();

  return (
    <Modal modalTitle="Cliente" onClose={onClose} hideButton>
      <InfoRow>
        <DocumentButton>{/* <DocumentIcon /> */}</DocumentButton>
        <InfoSvgRow style={{ marginBottom: 0 }}>
          <Button onClick={() => showEditModal?.()}>
            <EditIcon />
          </Button>
          <Button onClick={() => showDeleteModal?.()}>
            <TrashIcon />
          </Button>
        </InfoSvgRow>
      </InfoRow>
      <InfoArea>
        <Name>{client?.name}</Name>
        {client?.vacancy_relation && (
          <Occupation>{client.vacancy_relation.name}</Occupation>
        )}

        <Description>{client?.description}</Description>

        <RowTitle>Dados da empresa</RowTitle>
        <ContactSvgRow>
          <MailIcon />
          <Contact>{client?.email}</Contact>
        </ContactSvgRow>
        {client?.local && (
          <ContactSvgRow>
            <PinIcon />
            <Contact>{client?.local}</Contact>
          </ContactSvgRow>
        )}
        <ContactSvgRow style={{ marginBottom: 30 }}>
          <CpfIcon></CpfIcon>
          <Contact>{formatCNPJ(client?.cnpj)}</Contact>
        </ContactSvgRow>

        <RowTitle>Dados da Vaga</RowTitle>
        {client?.vacancy_relation ? (
          <>
            <InfoSvgRow>
              <AmountIcon />
              <Amount>{client.vacancy_relation.quantity} vagas</Amount>
            </InfoSvgRow>
            <InfoSvgRow style={{ marginBottom: 30 }}>
              <MoneyIcon />
              <Salary>R$ {client.vacancy_relation.budget}</Salary>
            </InfoSvgRow>
          </>
        ) : (
          <CreateVacancyButton
            title="Criar Vaga Relacionada"
            callback={() => navigation(`/criar-vaga/${client?.id}`)}
            customStyle={{ marginTop: 20 }}
          />
        )}

        {client?.contract_validate && (
          <>
            <RowTitle>Contrato</RowTitle>
            <ContactSvgRow>
              <CalendarIcon />
              <CalendarRow>
                <Contact>{client?.created_at}</Contact>
                <ContainerIndicator>
                  <Circle />
                  <Line />
                  <Circle />
                </ContainerIndicator>
                <Contact>{client?.contract_validate}</Contact>
              </CalendarRow>
            </ContactSvgRow>
          </>
        )}
      </InfoArea>
    </Modal>
  );
};

export default RecrutamentViewModal;
const formatCNPJ = (value?: string) => {
  if (!value) return;
  if (value.length > 18) return; // CNPJ formatado: 00.000.000/0000-00
  value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
  value = value.replace(/(\d{2})(\d)/, '$1.$2'); // Adiciona o primeiro ponto
  value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Adiciona o segundo ponto
  value = value.replace(/(\d{3})(\d)/, '$1/$2'); // Adiciona a barra
  value = value.replace(/(\d{4})(\d{1,2})$/, '$1-$2'); // Adiciona o hífen antes dos últimos dois dígitos
  return value;
};
