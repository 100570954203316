import React from 'react';

import { CloseButton, CloseIcon, Container, Title } from './styles';
import typ from './index.d';

const TitleRow : React.FC<typ.Props> = ({modalTitle, onClose}) => {
   return (
        <Container>
            <Title>{modalTitle}</Title>
            <CloseButton onClick={onClose}>
                <CloseIcon />
            </CloseButton>
        </Container>
    );
}

export default TitleRow;