const transformarData = (data: string) => {
  const [ano, mes, dia] = data.split('T')[0].split('-');
  return `${dia}/${mes}/${ano}`;
};

function limitarString(texto: string, maxLength: number) {
  if (texto.length > maxLength) {
    return texto.slice(0, maxLength - 3) + '...';
  }
  return texto;
}

function formatarPhone(numero: string | undefined) {
  return `(${numero?.slice(0, 2)}) ${numero?.slice(2, 7)}-${numero?.slice(7)}`;
}

function formatCPF(cpf: string) {
  cpf = cpf.replace(/\D/g, '');

  if (cpf.length <= 11) {
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }

  return cpf;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const convertDateToISO = (date: string) => {
  const [day, month, year] = date.split('/'); // Divide a string em dia, mês e ano
  return `${year}-${month}-${day}`; // Reorganiza para o formato AAAA-MM-DD
};

export {
  transformarData,
  limitarString,
  formatarPhone,
  capitalizeFirstLetter,
  formatCPF,
  convertDateToISO
};
