import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import ProspectClientCRUDProps from './index.d';

class ProspectClientCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }
  create({
    name,
    email,
    phone,
    company_type,
  }: ProspectClientCRUDProps.createParams) {
    return axiosManager<ProspectClientCRUDProps.createReturn>({
      method: 'post',
      url: `/prospect-client/create`,
      auth: this.token,
      payload: {
        name,
        email,
        phone,
        company_type,
      },
    });
  }

  read({ page = 1, per_page = 10 }: ProspectClientCRUDProps.readParams) {
    let url = `/prospect-client/list?page=${page}&per_page=${per_page}`;
    return axiosManager<ProspectClientCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }

  update({
    company_type,
    email,
    name,
    phone,
    id,
  }: ProspectClientCRUDProps.updateParams) {
    return axiosManager<ProspectClientCRUDProps.updateReturn>({
      method: 'put',
      url: `/prospect-client/update/${id}`,
      auth: this.token,
      payload: { phone, name, email, company_type },
    });
  }

  delete({ id }: ProspectClientCRUDProps.deleteParams) {
    return axiosManager<ProspectClientCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/prospect-client/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }
}

export { ProspectClientCRUD };
