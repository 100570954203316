import React from 'react';

import { Card, CardBancoImg, CardConsultoresImg, CardRecolocamentoImg, CardRecrutamentoImg, CardsArea, CardTitle, CardVagasImg, Container, Content, HeaderImageContainer, HeaderImg, HeaderTitle } from './styles';
import MenuBar from '../../../components/menu-bar';
import ResponsiveContainer from '../../../components/container';

const Home:React.FC = () => {
   return (
      <Container>
         <MenuBar currentPage='Home'/>
         <Content>
            <ResponsiveContainer>
               <HeaderImageContainer>
                  <HeaderImg />
                  <HeaderTitle>Escolha como você vai <br /> trabalhar hoje</HeaderTitle>
               </HeaderImageContainer>
               <CardsArea>
                  <Card href='/recolocacao-profissional'>
                     <CardRecolocamentoImg />
                     <CardTitle>Recolocação <br /> profissional</CardTitle>
                  </Card>
                  <Card href='/recrutamento'>
                     <CardRecrutamentoImg />
                     <CardTitle>Recrutamento <br />e seleção</CardTitle>
                  </Card>
                  <Card href='/vagas'>
                     <CardVagasImg />
                     <CardTitle>Vagas</CardTitle>
                  </Card>
                  <Card href='/consultores'>
                     <CardConsultoresImg />
                     <CardTitle>Consultores</CardTitle>
                  </Card>
                  <Card href='/banco-de-talentos'>
                     <CardBancoImg />
                     <CardTitle>Banco de talentos</CardTitle>
                  </Card>
               </CardsArea>
            </ResponsiveContainer>
         </Content>
      </Container>
   );
}

export default Home;