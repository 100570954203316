import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import PersonalClientCRUDProps from './index.d';

// const token =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzX2luIjoiMjAyNC0xMS0yNyAwMjoxOTozOS40MjAyODkiLCJjcmVhdGVkX2F0IjoiMjAyNC0xMC0yOCAwMjoxOTozOS40MjAzMjUiLCJ1c2VybmFtZSI6IkJhcmJhcmEiLCJ1c2VyX2lkIjo0fQ.QNZc2cR7uZpOruuelthLMOafRC0kDR-FA3eNLVpnptI';

class PersonalClientCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token');
  }

  create({
    advisor_id,
    backup_phone,
    birth_date,
    budget,
    client_type,
    cpf,
    email,
    kanban_step,
    key_words,
    local,
    marital_status,
    name,
    number_children,
    occupation,
    personal_phone,
    sexual_gender,
    status,
    attachment,
    image,
    moving_conditions,
    replacement_company,
    replacement_office,
    replacement_salary,
    replacement_start_date,
  }: PersonalClientCRUDProps.createParams) {
    const data = new FormData();

    // advisor_id && data.append('advisor_id', advisor_id);
    // backup_phone && data.append('backup_phone', backup_phone);
    // birth_date && data.append('birth_date', birth_date);
    // budget && data.append('budget', budget);
    // client_type && data.append('client_type', client_type);
    // cpf && data.append('cpf', cpf);
    // email && data.append('email', email);
    // kanban_step && data.append('kanban_step', kanban_step);
    // key_words && data.append('key_words', key_words);
    // local && data.append('local', local);
    // marital_status && data.append('marital_status', marital_status);
    // name && data.append('name', name);
    // number_children && data.append('number_children', number_children);
    // occupation && data.append('occupation', occupation);
    // personal_phone && data.append('personal_phone', personal_phone);
    // sexual_gender && data.append('sexual_gender', sexual_gender);
    // status && data.append('status', status);
    // attachment && data.append('attachment', attachment);
    // image && data.append('image', image);

    return axiosManager<PersonalClientCRUDProps.createReturn>({
      method: 'post',
      url: `/personal-client/create`,
      auth: this.token || '',
      payload: {
        advisor_id,
        backup_phone,
        birth_date,
        budget,
        client_type,
        cpf,
        email,
        kanban_step,
        key_words,
        local,
        marital_status,
        name,
        number_children,
        occupation,
        personal_phone,
        sexual_gender,
        status,
        moving_conditions,
        replacement_company,
        replacement_office,
        replacement_salary,
        // replacement_start_date,
      },
    });
  }

  read({ page = 1, per_page = 10 }: PersonalClientCRUDProps.readParams) {
    return axiosManager<PersonalClientCRUDProps.readReturn>({
      method: 'get',
      url: `personal-client/list?page=${page}&per_page=${per_page}`,
      auth: this.token || '',
      payload: null,
    });
  }

  update({
    backup_phone,
    birth_date,
    budget,
    client_type,
    cpf,
    email,
    kanban_step,
    key_words,
    local,
    marital_status,
    name,
    number_children,
    occupation,
    personal_phone,
    sexual_gender,
    status,
    id,
    moving_conditions,
    replacement_company,
    replacement_office,
    replacement_salary,
  }: PersonalClientCRUDProps.updateParams) {
    return axiosManager<PersonalClientCRUDProps.updateReturn>({
      method: 'put',
      url: `/personal-client/update/${id}`,
      auth: this.token || '',
      payload: {
        backup_phone,
        status,
        birth_date,
        budget,
        client_type,
        cpf,
        email,
        kanban_step,
        key_words,
        local,
        marital_status,
        name,
        number_children,
        occupation,
        personal_phone,
        sexual_gender,
        moving_conditions,
        replacement_company,
        replacement_office,
        replacement_salary,
      },
    });
  }

  modalView({ id, name }: PersonalClientCRUDProps.modalViewParams) {
    let url = `/personal-client/view/modal`;
    if (id) url = url + `?id=${id}`;
    if (name) url = url + `?name=${name}`;

    return axiosManager<PersonalClientCRUDProps.modalViewReturn>({
      method: 'get',
      url,
      auth: this.token || '',
      payload: null,
    });
  }

  view({ id }: PersonalClientCRUDProps.viewParams) {
    let url = `/personal-client/view`;
    if (id) url = url + `?id=${id}`;

    return axiosManager<PersonalClientCRUDProps.viewReturn>({
      method: 'get',
      url,
      auth: this.token || '',
      payload: null,
    });
  }

  delete({ id }: PersonalClientCRUDProps.deleteParams) {
    return axiosManager<PersonalClientCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/personal-client/delete/${id}`,
      auth: this.token || '',
      payload: null,
    });
  }
}

export { PersonalClientCRUD };
