import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import LanguageCRUDProps from './index.d';

class LanguageCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }
  create({
    language_name,
    language_level,
    id,
    isVancacie,
  }: LanguageCRUDProps.createParams) {
    let data: LanguageCRUDProps.createData = { language_level, language_name };
    if (isVancacie) data.vacancy_id = id;
    else data.personal_client_id = id;

    return axiosManager<LanguageCRUDProps.createReturn>({
      method: 'post',
      url: `/language/create`,
      auth: this.token,
      payload: data,
    });
  }

  read({
    isVancacie,
    id,
    page = 1,
    per_page = 10,
  }: LanguageCRUDProps.readParams) {
    let url = `/language/list?page=${page}&per_page=${per_page}`;

    if (isVancacie) url = url + `&vacancy_id=${id}`;
    else url = url + `&personal_client_id=${id}`;

    return axiosManager<LanguageCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }

  update({
    language_level,
    language_name,
    id,
  }: LanguageCRUDProps.updateParams) {
    return axiosManager<LanguageCRUDProps.updateReturn>({
      method: 'put',
      url: `/language/update/${id}`,
      auth: this.token,
      payload: { language_level, language_name },
    });
  }

  delete({ id }: LanguageCRUDProps.deleteParams) {
    return axiosManager<LanguageCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/language/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }
}

export { LanguageCRUD };
