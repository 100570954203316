import React from 'react';

import {
  ConsultantName,
  ConsultantPhoto,
  Container,
  ExitIcon,
  ItensArea,
  LogoArea,
  LogoMenu,
  LogoText,
  UserArea,
} from './styles';
import MenuItemComp from './menu-item';
import typ from './index.d';
import { AdvisorCRUD } from '../../services/requests/advisor';
import { useAppActions, useAppSelector } from '../../hooks/useRedux';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const advisorCrud = new AdvisorCRUD();

const MenuBar: React.FC<typ.Props> = ({ currentPage }) => {
  const currentUser = useAppSelector(state => state.state.user);
  const { signOut } = useAppActions();
  const navigation = useNavigate();
  const [advisor, setAdvisor] = React.useState<typ.advisor>();

  const getCurrentAdvisor = React.useCallback(async () => {
    const { data, status } = await advisorCrud.read({
      id: Number(currentUser?.id) || 0,
    });

    if (status && data) {
      setAdvisor({ image: data.Advisor[0].image, name: data.Advisor[0].name });
    }
  }, []);

  React.useEffect(() => {
    getCurrentAdvisor();
  }, []);
  return (
    <Container>
      <ItensArea>
        <LogoArea>
          <LogoMenu />
          <LogoText>
            consultoria <br /> recursos <br /> humanos
          </LogoText>
        </LogoArea>
        <MenuItemComp
          active={currentPage === 'Home' ? true : false}
          title="Home"
          href="/"
        />
        <MenuItemComp
          active={currentPage === 'Vagas'}
          title="Vagas"
          href="/vagas"
        />
        <MenuItemComp
          active={currentPage === 'Recrutamento'}
          title="Recrutamento"
          href="/recrutamento"
        />
        <MenuItemComp
          active={currentPage === 'Recolocação'}
          title="Recolocação"
          href="/recolocacao-profissional"
        />
        <MenuItemComp
          active={currentPage === 'Consultores'}
          title="Consultores"
          href="/consultores"
        />
        <MenuItemComp
          active={currentPage === 'Talentos'}
          title="Talentos"
          href="/banco-de-talentos"
        />
        <MenuItemComp
          active={currentPage === 'Prospecção'}
          title="Prospecção"
          href="/prospeccao"
        />
      </ItensArea>
      <UserArea>
        <ConsultantPhoto
          src={advisor?.image || require('../../assets/temp/temp-user.png')}
        />
        <ConsultantName>{advisor?.name}</ConsultantName>
        <ExitIcon
          onClick={() => {
            Cookies.remove('token');
            Cookies.remove('id');
            signOut();
            navigation('/');
            toast('Usuário deslogado');
          }}
        />
      </UserArea>
    </Container>
  );
};

export default MenuBar;
