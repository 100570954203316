import React from 'react';
import RoutesComponent from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppActions } from './hooks/useRedux';

function App() {
  const { checkAuth } = useAppActions();

  React.useEffect(() => {
    checkAuth();
  }, []);
  return (
    <div className="App">
      <RoutesComponent />
      <ToastContainer />
    </div>
  );
}

export default App;
