import styled from 'styled-components';

export const Container = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   padding: 20px;
   height: 100vh;
   @media only screen and (min-width: 480px){
       padding: 40px;
   }
   @media only screen and (min-width: 991px){
       justify-content: space-between;
   }
   @media only screen and (min-width: 1200px){
       padding: 60px 85px 60px 60px;
   }     
   @media only screen and (min-width: 1350px){     
       padding: 60px 200px 60px 60px;
    }
   @media only screen and (min-width: 1600px){     
       padding: 60px 300px 60px 60px;
    }
`;

export const DescriptionArea = styled.div`
    display: none;
    @media only screen and (min-width: 991px){
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
    }     
    @media only screen and (min-width: 1600px){     
        margin-right: 50px;
    }
`;

export const Description = styled.h1`
    font-size: 24px;
    color: ${({theme}) => theme.colors.text};
    font-family: ${({theme}) => theme.fontFamily.poppins.semiBold};
    
    @media only screen and (min-width: 480px){     
        font-size: 28px;       
    }
    @media only screen and (min-width: 768px){     
        font-size: 32px;
    }
    @media only screen and (min-width: 991px){     
        font-size: 34px;
    }
    @media only screen and (min-width: 1200px){     
        font-size: 42px;
    }
    @media only screen and (min-width: 1600px){     
        font-size: 49px;
    }
`;

export const LoginArea = styled.div`
    display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   max-width: 348px;
   @media only screen and (min-width: 991px){
        width: 50%;
        max-width: 348px;
        margin-top: -110px;
   } 
`;

export const Logo = styled.img.attrs({src: require('../../../assets/images/Logo.png')})`
    margin-bottom: 50px;
    max-width: 150px;
    @media only screen and (min-width: 768px){
        max-width: 180px;
    }     
    @media only screen and (min-width: 991px){     
        max-width: max-content;
    }
    @media only screen and (min-width: 1200px){     
        margin-bottom: 125px;
    }
`;

export const InputArea = styled.div`
    width: 100%;
    display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   @media only screen and (min-width: 991px){ 
       align-items: flex-start;
   }
`;

export const LoginTitle = styled.h3`
    font-family: ${({theme}) => theme.fontFamily.poppins.semiBold};
    color: ${({theme}) => theme.colors.text};
    
    font-size: 24px;
    margin: 0 0 12px;
    @media only screen and (min-width: 768px){
        font-size: 26px;
        margin: 0 0 28px;
    }     
    @media only screen and (min-width: 991px){     
        font-size: 30px;
        margin: 0 0 20px;
    }
    
    @media only screen and (min-width: 1600px){     
        font-size: 40px;
        margin: 0 0 30px;
    }
`;

export const InfoArea = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 12px;
    @media only screen and (min-width: 480px){
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    @media only screen and (min-width: 1200px){
        margin-bottom: 20px;
    }
`;

export const RememberMeArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    @media only screen and (min-width: 480px){
        margin-bottom: 0px;
    }
`;

export const RememberMe = styled.p`
    color: ${({theme}) => theme.colors.text};
    font-size: 16px;
    font-family: ${({theme}) => theme.fontFamily.poppins.regular};
    margin: 0 0 0 5px;
`;

export const CheckBok = styled.input.attrs({type: 'checkbox'})`
    width: 16px;
    border: 1px solid ${({theme}) => theme.colors.text}
`;

export const ForgottPass = styled.button`
    background-color: transparent;
    border: none;
    color: ${({theme}) => theme.colors.blue};
    text-decoration: underline;
    cursor: pointer;

    transition: color 350ms ease; /* Suave transição */
    &:hover {
        color: ${({theme}) => theme.colors.primary}; /* Cor de fundo no hover */
    }
`;

export const Elipse = styled.div<{elipseColor: string}>`
    width: 341px;
    height: 341px;
    border-radius: 50%;
    background-color: ${({elipseColor}) => elipseColor};
    filter: blur(150px)
`;

export const ElipseMobile = styled.div<{elipseColor: string}>`
    width: 341px;
    height: 341px;
    border-radius: 50%;
    background-color: ${({elipseColor}) => elipseColor};
    filter: blur(180px);

    @media only screen and (min-width: 991px){
        display: none;
    }

`;