import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  BackFullIcon,
  Container,
  Content,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  LoadRow,
} from './styles';
import MenuBar from '../../../components/menu-bar';
import Search from '../../../components/search';
import { toast } from 'react-toastify';
import {
  Card,
  ControlledBoard,
  KanbanBoard,
  moveCard,
  OnDragEndNotification,
} from '@caldwell619/react-kanban';
import '@caldwell619/react-kanban/dist/styles.css'; // import here for "builtin" styles
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { PersonalClientCRUD } from '../../../services/requests/personal-client';
import Load from '../../../components/load';
import { CONST_PERSONAL_CLIENT_KAMBAM_STEP } from '../../../utils/CONSTANTS';
import PersonalClientModal from '../../../components/modais/personal-client';
import CardUserJob from '../../../components/cards/user-job';

const personalClientCrud = new PersonalClientCRUD();

const RecolocationPage: React.FC = () => {
  // Params
  const { colors } = useTheme();
  const navigation = useNavigate();
  const isApiRunning = React.useRef(false);

  // States
  const [search, setSearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [currentClient, setCurrentClient] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [controlledBoard, setBoard] = React.useState<KanbanBoard<any>>({
    columns: [],
  });

  const handleCardMove: OnDragEndNotification<Card> = (
    _card,
    source,
    destination,
  ) => {
    setBoard(currentBoard => {
      handleUpdateVancancie(Number(_card.id), Number(destination?.toColumnId));
      return moveCard(currentBoard, source, destination);
    });
  };

  const refreshData = React.useCallback(async (page?: number) => {
    if (isApiRunning.current) return;
    try {
      isApiRunning.current = true;
      setLoading(true);
      // currentPage.current = page || 1;
      const { data, status, error } = await personalClientCrud.read({
        page,
        per_page: 30,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      console.log(data);

      if (status === 200 && data) {
        let currentData = KAMBAM_DATA;

        data.PersonalClient.forEach(item => {
          currentData.columns = currentData.columns.map(column => {
            if (column.title === item.kanban_step) {
              return {
                ...column,
                cards: [...column.cards, item],
              };
            }
            return column;
          });
        });

        console.log(currentData);
        setBoard(currentData);
      }
    } catch (e) {
      alert(e);
    } finally {
      isApiRunning.current = false;
      setLoading(false);
    }
  }, []);

  const handleUpdateVancancie = React.useCallback(
    async (idCard: number, columnId: number) => {
      let kambamStep: string;
      let currentKambam: string;
      kambamStep = KAMBAM_DATA.columns[columnId].title;

      if (
        CONST_PERSONAL_CLIENT_KAMBAM_STEP.findIndex(
          item => item.label.toLowerCase() === kambamStep.toLocaleLowerCase(),
        ) >= 0
      ) {
        currentKambam =
          CONST_PERSONAL_CLIENT_KAMBAM_STEP[
            CONST_PERSONAL_CLIENT_KAMBAM_STEP.findIndex(
              item =>
                item.label.toLowerCase() === kambamStep.toLocaleLowerCase(),
            )
          ].keys;
      } else {
        currentKambam = kambamStep;
      }

      try {
        const {
          data,
          status: Status,
          error,
        } = await personalClientCrud.update({
          id: idCard,
          kanban_step: currentKambam,
        });

        if (error) throw new Error(error.message.description);
        if (Status === 200 && data) {
          toast('Passo no Kambam atualizado com sucesso.');
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  React.useEffect(() => {
    refreshData();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Recolocação" />
      <Content>
        <Header>
          <HeaderBack href="/">
            <BackFullIcon />
          </HeaderBack>
          <HeaderInfo>
            <HeaderTitle>Recolocação profissional</HeaderTitle>
            <HeaderActions>
              <Search
                value={search}
                setValue={setSearch}
                placeholder="Pesquisar clientes"
              />
              <AddConsultantButton
                title="Adicionar cliente"
                iconRight={AddIcon}
                callback={() => navigation('/criar-cliente-recolocacao')}
              />
            </HeaderActions>
          </HeaderInfo>
        </Header>

        {loading ? (
          <LoadRow>
            <Load
              customStyle={{
                color: colors.secondaryDark,
              }}
              size="extraLarge"
            />
          </LoadRow>
        ) : (
          <>
            {controlledBoard.columns.length > 0 ? (
              <KambamStyles>
                <ControlledBoard
                  disableColumnDrag
                  allowAddCard={false}
                  allowAddColumn={false}
                  allowRenameColumn={false}
                  onCardDragEnd={handleCardMove}
                  onCardRemove={({ board, card, column }) => {
                    console.log({ board, card, column });
                  }}
                  allowRemoveColumn={false}
                  allowRemoveCard={false}
                  renderCard={(card: any, options) => (
                    <CardUserJob
                      onClick={() => {
                        setCurrentClient(card.id);
                        setShowModal(true);
                      }}
                      client={{
                        advisor_relation: card.advisor_relation,
                        budget: card.budget,
                        client_type: card.client_type,
                        cpf: card.cpf,
                        id: card.id,
                        image: card.image,
                        kanban_step: card.kanban_step,
                        local: card.local,
                        name: card.name,
                        occupation: card.occupation,
                        status: card.status,
                        vacancy_matching_quantity:
                          card.vacancy_matching_quantity,
                      }}
                    />
                  )}>
                  {controlledBoard}
                </ControlledBoard>
              </KambamStyles>
            ) : (
              <HeaderTitle>Nenhum cliente cadastrado...</HeaderTitle>
            )}
          </>
        )}
      </Content>
      {showModal && (
        <PersonalClientModal
          personalClient={currentClient}
          refreshData={refreshData}
          onClose={() => {
            setShowModal(false);
            setCurrentClient(undefined);
          }}
        />
      )}
    </Container>
  );
};

export default RecolocationPage;

const KambamStyles = styled('div')`
  flex: 1;
  display: flex;
  & * {
    /* font-family: 'Segoe' !important; */
  }
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Inter' !important;
  }
  & .react-kanban-column {
    padding: 0;
    background-color: transparent;
    margin-right: 30px;
    &:nth-child(1) {
      & .react-kanban-column-header {
        background-color: #7f2c7c;
      }
    }
    &:nth-child(2) {
      & .react-kanban-column-header {
        background-color: #4a94f8;
      }
    }
    &:nth-child(3) {
      & .react-kanban-column-header {
        background-color: #56c991;
      }
    }
    &:nth-child(4) {
      & .react-kanban-column-header {
        background-color: #ff6262;
      }
    }
    &:nth-child(5) {
      & .react-kanban-column-header {
        background-color: #b55bcc;
      }
    }
    &:nth-child(6) {
      & .react-kanban-column-header {
        background-color: #8075ff;
      }
    }
    &:nth-child(7) {
      & .react-kanban-column-header {
        background-color: #bfb060;
      }
    }
    &:nth-child(8) {
      & .react-kanban-column-header {
        background-color: #ff72f1;
      }
    }
    &:nth-child(9) {
      & .react-kanban-column-header {
        background-color: #56c991;
      }
    }
  }
  & .react-kanban-column-header {
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 25px;
    & span {
      color: white;
    }
  }
  & .react-kanban-card {
    margin-bottom: 20px;
  }
`;

const KAMBAM_DATA: {
  columns: {
    id: number;
    title: string;
    cards: any;
  }[];
} = {
  columns: [
    {
      id: 0,
      title: 'Cadastro',
      cards: [],
    },
    {
      id: 1,
      title: 'Retrospectiva de carreira',
      cards: [],
    },
    {
      id: 2,
      title: 'Aprovar currículo',
      cards: [],
    },
    {
      id: 3,
      title: 'Formulário de autoanálise',
      cards: [],
    },
    {
      id: 4,
      title: 'Discussão de autoanálise',
      cards: [],
    },
    {
      id: 5,
      title: 'Entrevista simulada',
      cards: [],
    },
    {
      id: 6,
      title: 'Dinâmica simulada',
      cards: [],
    },
    {
      id: 7,
      title: 'Workshop linkedin',
      cards: [],
    },
    {
      id: 8,
      title: 'Finalizado',
      cards: [],
    },
  ],
};

export interface CustomCard extends Card {
  id: number;
  name: string;
  image: string | null;
  occupation: string;
  status: string;
  client_type: string;
  kanban_step: string;
  budget: string;
  cpf: string;
  local: string;
  advisor_relation: {
    id: number;
    image: string | null;
    name: string;
  } | null;
  vacancy_matching_quantity: number;
  onClick?: () => void;
}
