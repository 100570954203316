import React from 'react';

import {
  Card,
  CardRow,
  CardRowForSvg,
  Button,
  DocumentIcon,
  Locality,
  MoneyIcon,
  PinIcon,
  Salary,
  EditIcon,
  TrashIcon,
  DocumentArea,
  ActionsRow,
  MailIcon,
  Mail,
  UserImg,
  Name,
  Job,
  CardColumn,
  ConsultantImg,
  Link,
} from './styles';
import { limitarString } from '../../../utils/convert';

import typ from './index.d';
import { useNavigate } from 'react-router-dom';
import { TalentPoolCRUD } from '../../../services/requests/talent-pool';

const talentPoolCrud = new TalentPoolCRUD();

const CardUserInfo: React.FC<typ.Props> = ({
  talentPool,
  deleteCallback,
  editCallback,
  onClick,
  removeMargin,
  customStyle,
}) => {
  return (
    <Card
      onClick={() => {
        if (onClick) onClick();
      }}
      style={removeMargin ? { margin: 0 } : { zIndex: 10 }}>
      <ActionsRow>
        <Link onClick={() => console.log('a')}>
          <DocumentArea>
            <DocumentIcon />
          </DocumentArea>
        </Link>
        <CardRowForSvg>
          <Button onClick={editCallback}>
            <EditIcon />
          </Button>
          <Button onClick={deleteCallback}>
            <TrashIcon />
          </Button>
        </CardRowForSvg>
      </ActionsRow>

      <CardRow style={{ marginBottom: 10 }}>
        <CardRowForSvg>
          <UserImg
            src={
              talentPool.image || require('../../../assets/temp/temp-user.png')
            }
          />
          <CardColumn>
            <Name>{limitarString(talentPool.name, 21)}</Name>
            <Job>{talentPool.occupation}</Job>
          </CardColumn>
        </CardRowForSvg>
      </CardRow>

      {talentPool.email && (
        <CardRowForSvg style={{ marginBottom: 15 }}>
          <MailIcon />
          <Mail>{talentPool.email}</Mail>
        </CardRowForSvg>
      )}

      <CardRow>
        {talentPool.budget && (
          <CardRowForSvg>
            <MoneyIcon />
            <Salary>R$ {talentPool.budget}</Salary>
          </CardRowForSvg>
        )}
        {talentPool.local && (
          <CardRowForSvg>
            <PinIcon />
            <Locality>{limitarString(talentPool.local, 17)}</Locality>
          </CardRowForSvg>
        )}
      </CardRow>
    </Card>
  );
};

export default CardUserInfo;
