import React from 'react';
import {
  AddIcon,
  BackFullIcon,
  ButtonAddKeyWord,
  ButtonAddVancacie,
  ButtonRow,
  Container,
  Content,
  Header,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  InputContainer,
  InputsContactRow,
  InputsVancacie,
  InputsVancacieRow,
  KeyWord,
  KeyWordsArea,
  SectionTitle,
  VacanciesContainer,
} from './styles';
import MenuBar from '../../../../components/menu-bar';
import ResponsiveContainer from '../../../../components/container';
import { VancancieCRUD } from '../../../../services/requests/vancacies';
import InputSelectComponent from '../../../../components/input-select';
import {
  CONST_LEVEL,
  CONST_OCCUPATION,
  CONST_PRIORITYS,
  CONST_SEXUALIDADE,
  CONST_VACANCIETYPE,
} from '../../../../utils/CONSTANTS';
import { useAppActions } from '../../../../hooks/useRedux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const vancacieCrud = new VancancieCRUD();

const CreateVancacie: React.FC = () => {
  const { id } = useParams();

  // States
  const [name, setName] = React.useState('');
  const [quantity, setQuantity] = React.useState('');
  const [level, setLevel] = React.useState('');
  const [occupation, setOccupation] = React.useState('');
  const [budget, setBudget] = React.useState('');
  const [sex, setSex] = React.useState('');
  const [priority, setPriority] = React.useState('');
  const [type, setType] = React.useState('');
  const [benefits, setBenefits] = React.useState('');
  const [perfil, setPerfil] = React.useState('');
  const [workplace, setWorkplace] = React.useState('');
  const [keyWord, setKeyWord] = React.useState('');
  const [keyWordList, setKeyWordList] = React.useState<string[]>();

  const [nameContact, setNameContact] = React.useState('');
  const [mailContact, setMailContact] = React.useState('');
  const [phoneContact, setPhoneContact] = React.useState('');
  const [occupationContact, setOccupationContact] = React.useState('');

  const [warning, setWarning] = React.useState({
    name: false,
    quantity: false,
    level: false,
    occupation: false,
    budget: false,
    sex: false,
    type: false,
    nameContact: false,
    mailContact: false,
    phoneContact: false,
    occupationContact: false,
  });

  // Callbacks
  const handleCreateVancancie = React.useCallback(
    async (
      name: string,
      quantity: string,
      level: string,
      occupation: string,
      budget: string,
      sex: string,
      priority: string,
      type: string,
      benefits: string,
      perfil: string,
      workplace: string,
      keyWordList: string[] | undefined,
      nameContact: string,
      mailContact: string,
      phoneContact: string,
      occupationContact: string,
    ) => {
      if (
        !name &&
        !quantity &&
        !level &&
        !occupation &&
        !budget &&
        !sex &&
        !nameContact &&
        !mailContact &&
        !phoneContact &&
        !occupationContact &&
        !type
      ) {
        setWarning({
          name: true,
          quantity: true,
          level: true,
          occupation: true,
          budget: true,
          sex: true,
          type: true,
          nameContact: true,
          mailContact: true,
          phoneContact: true,
          occupationContact: true,
        });
        toast('Nenhum campo foi preenchido');
        return;
      }
      if (!name) {
        setWarning(e => ({ ...e, name: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!quantity) {
        setWarning(e => ({ ...e, quantity: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!level) {
        setWarning(e => ({ ...e, level: true }));
        toast('Preencha todos os campos indicadosss.');
        return;
      }
      if (!occupation) {
        setWarning(e => ({ ...e, occupation: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!budget) {
        setWarning(e => ({ ...e, budget: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!sex) {
        setWarning(e => ({ ...e, sex: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!type) {
        setWarning(e => ({ ...e, type: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!nameContact) {
        setWarning(e => ({ ...e, nameContact: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!mailContact) {
        setWarning(e => ({ ...e, mailContact: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!phoneContact) {
        setWarning(e => ({ ...e, phoneContact: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }
      if (!occupationContact) {
        setWarning(e => ({ ...e, occupationContact: true }));
        toast('Preencha todos os campos indicados.');
        return;
      }

      if (!mailContact.includes('@')) {
        toast('Digite o email corretamente');
        setWarning(e => ({ ...e, mailContact: true }));
        return;
      }

      try {
        const { data, status, error } = await vancacieCrud.create({
          name,
          quantity: parseInt(quantity),
          level,
          occupation: occupation,
          budget,
          sexual_gender: sex,
          contact_name: nameContact,
          contact_phone: phoneContact.replace(/\D/g, ''),
          contact_role: occupationContact,
          email: mailContact,
          employment_type: type,
          benefits,
          perfil,
          workplace,
          key_words: keyWordList || ['gestao'],
          priority,
          corporate_client_id: Number(id) || undefined,
        });

        if (error) throw new Error(error.message.description);
        if (status === 200 && data) {
          toast('Vaga criada com sucesso.');
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const formatPhoneNumber = (value: string) => {
    if (value.length > 15) return;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    setPhoneContact(value);
  };

  return (
    <Container>
      <MenuBar currentPage="Vagas" />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/vagas">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Preencha os campos abaixo </HeaderTitle>
            </HeaderInfo>
          </Header>
          <VacanciesContainer>
            <InputContainer>
              <SectionTitle>Dados da vaga</SectionTitle>
              <InputsVancacieRow>
                <InputsVancacie
                  customContainerSize="full"
                  customStyle={{ maxWidth: 'none' }}
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={name}
                  setValue={setName}
                  placeholder="Nome da vaga*"
                  warning={warning.name}
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={quantity}
                  setValue={setQuantity}
                  placeholder="Quantidade de vagas*"
                  warning={warning.quantity}
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={budget}
                  setValue={setBudget}
                  placeholder="Salário*"
                  warning={warning.budget}
                />
                <InputSelectComponent
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={level}
                  setValue={setLevel}
                  placeholder="Nível*"
                  warning={warning.level}
                  selectData={CONST_LEVEL}
                />
                <InputSelectComponent
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={occupation}
                  setValue={setOccupation}
                  placeholder="Cargo*"
                  warning={warning.occupation}
                  selectData={CONST_OCCUPATION}
                />
                <InputSelectComponent
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={sex}
                  setValue={setSex}
                  placeholder="Sexo*"
                  warning={warning.sex}
                  selectData={CONST_SEXUALIDADE}
                />
                <InputSelectComponent
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={priority}
                  setValue={setPriority}
                  placeholder="Prioridade"
                  selectData={CONST_PRIORITYS}
                />
                <InputSelectComponent
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={type}
                  setValue={setType}
                  placeholder="Tipo da vaga*"
                  warning={warning.type}
                  selectData={CONST_VACANCIETYPE}
                />
                <InputsVancacie
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={perfil}
                  setValue={setPerfil}
                  placeholder="Perfil"
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={workplace}
                  setValue={setWorkplace}
                  placeholder="Local"
                />
                <InputsVancacie
                  customContainerSize="half"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={benefits}
                  setValue={setBenefits}
                  placeholder="Benefícios"
                />
                <InputsVancacie
                  customContainerSize="full"
                  customStyle={{ maxWidth: 'none' }}
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={keyWord}
                  setValue={setKeyWord}
                  placeholder="Palavras chaves">
                  <ButtonAddKeyWord
                    onClick={() => {
                      let current = keyWordList ? keyWordList : [];
                      if (keyWord && keyWord !== '') current.push(keyWord);
                      setKeyWord('');
                      setKeyWordList(current);
                    }}>
                    <AddIcon />
                  </ButtonAddKeyWord>
                </InputsVancacie>
                {keyWordList && keyWordList?.length > 0 && (
                  <KeyWordsArea>
                    {keyWordList.map((e, i) => (
                      <KeyWord>{e}</KeyWord>
                    ))}
                  </KeyWordsArea>
                )}
              </InputsVancacieRow>
            </InputContainer>
            <InputContainer>
              <SectionTitle>Dados de contato</SectionTitle>
              <InputsContactRow>
                <InputsVancacie
                  customContainerSize="full"
                  customStyle={{ maxWidth: 'none' }}
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={nameContact}
                  setValue={setNameContact}
                  placeholder="Nome*"
                  warning={warning.nameContact}
                />
                <InputsVancacie
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={mailContact}
                  setValue={setMailContact}
                  placeholder="Email*"
                  warning={warning.mailContact}
                  type="email"
                />
                <InputsVancacie
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={phoneContact}
                  setValue={(e: any) => formatPhoneNumber(e)}
                  placeholder="Telefone*"
                  warning={warning.phoneContact}
                  isPhone
                />
                <InputsVancacie
                  customContainerSize="tird"
                  customStyleLabel={{ backgroundColor: '#f9f9f9' }}
                  value={occupationContact}
                  setValue={setOccupationContact}
                  placeholder="Cargo*"
                  warning={warning.occupationContact}
                />
              </InputsContactRow>
            </InputContainer>
          </VacanciesContainer>
          <ButtonRow>
            <ButtonAddVancacie
              title="Adicionar"
              callback={() =>
                handleCreateVancancie(
                  name,
                  quantity,
                  level,
                  occupation,
                  budget,
                  sex,
                  priority,
                  type,
                  benefits,
                  perfil,
                  workplace,
                  keyWordList,
                  nameContact,
                  mailContact,
                  phoneContact,
                  occupationContact,
                )
              }
            />
          </ButtonRow>
        </ResponsiveContainer>
      </Content>
    </Container>
  );
};

export default CreateVancacie;
