import React from 'react';

import typ from './index.d';
import {
  AmountIcon,
  ChosenArea,
  ChosenButton,
  ChosenLine,
  Content,
  MatchIcon,
  Modal,
  MoneyIcon,
  PinIcon,
  SearchArea,
  VacancieAmount,
  VacancieCategory,
  VacancieInfoRow,
  VacancieInfoSvgRow,
  VacancieLocality,
  VacancieMatchs,
  VacancieName,
  VacancieNumber,
  VacancieSalary,
  VacanciesCard,
} from './styles';
import { toast } from 'react-toastify';
import CardUserJob from '../../cards/user-job';
import Search from '../../search';
import { MatchCRUD } from '../../../services/requests/matchs';
import { VancancieCRUD } from '../../../services/requests/vancacies';
import { VancacieData } from '../../../services/requests/vancacies/index.d';
import { PersonalClientCRUD } from '../../../services/requests/personal-client';
import { PersonalClientData } from '../../../services/requests/personal-client/index.d';
import { useAppSelector } from '../../../hooks/useRedux';

const matchCRUD = new MatchCRUD();
const vancacieCRUD = new VancancieCRUD();
const personalClientCRUD = new PersonalClientCRUD();

const MatchModal: React.FC<typ.Props> = ({
  onClose,
  match,
  deleteCallback,
}) => {
  // Redux
  const user = useAppSelector(({ state }) => state.user);

  // States
  const [currentMatch, setMatch] = React.useState(match);
  const [client, setClient] = React.useState('');
  const [vacancy, setVacancy] = React.useState('');
  const [warning, setWarning] = React.useState({
    searchClient: false,
    searchVacancy: false,
  });

  const [currentVacancy, setCurrentVacancy] = React.useState<VancacieData>();
  const [vacancyLoading, setVacancyLoading] = React.useState(!match);

  const [currentPersonalClient, setCurrentPersonalClient] =
    React.useState<PersonalClientData>();
  const [personalClientLoading, setPersonalClientLoading] =
    React.useState(!match);

  const handleUpdate = React.useCallback(async (authorized: boolean) => {
    try {
      const { data, status, error } = await matchCRUD.update({
        vacancy_id: Number(currentMatch?.vacancy_id),
        advisor_id: Number(currentMatch?.advisor_relation.id),
        personal_client_id: Number(currentMatch?.personal_client_relation.id),
        authorized,
        match_id: Number(currentMatch?.id),
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        toast(`O match foi ${authorized ? 'aceito' : 'negado'}!`);
        handleOnClose();
      }
    } catch (e) {
      // alert(e);
    }
  }, []);

  const getVacancy = React.useCallback(async (vacancy?: string) => {
    try {
      // alert(currentMatch);
      setVacancyLoading(true);

      console.log('aaaaa');
      const { data, status, error } = await vancacieCRUD.read({
        id: currentMatch?.vacancy_id.toString(),
        name: vacancy,
      });

      console.log(data);

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setCurrentVacancy(data.Vacancy[0]);
      }
    } catch (e) {
      alert(e);
    } finally {
      setTimeout(() => {
        setVacancyLoading(false);
      }, 3000);
    }
  }, []);

  const getPersonalClient = React.useCallback(async (client?: string) => {
    try {
      setPersonalClientLoading(true);
      const { data, status, error } = await personalClientCRUD.modalView({
        id: currentMatch?.personal_client_relation.id || 0,
        name: client,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setCurrentPersonalClient(data.PersonalClient[0]);
      }
    } catch (e) {
      alert(e);
    } finally {
      setTimeout(() => {
        setPersonalClientLoading(false);
      }, 3000);
    }
  }, []);

  const handleOnClose = React.useCallback(() => {
    setCurrentPersonalClient(undefined);
    setCurrentVacancy(undefined);
    setMatch(undefined);
    onClose();
  }, []);

  const handleCreate = React.useCallback(
    async (v: VancacieData | undefined, p: PersonalClientData | undefined) => {
      try {
        console.log(v);
        if (!v) {
          toast('Pesquise uma vaga!');
          return;
        }
        if (!p) {
          toast('Pesquise um cliente!');
          return;
        }

        const { data, status, error } = await matchCRUD.create({
          vacancy_id: Number(v?.id),
          advisor_id: Number(user?.id),
          personal_client_id: Number(p?.id),
        });

        if (error) {
          throw new Error(error.message.description);
        }

        if (status === 200 && data) {
          toast(`O match foi criado!`);
          handleOnClose();
        }
      } catch (e) {
        // alert(e);
      }
    },
    [],
  );

  React.useEffect(() => {
    if (currentMatch) {
      getVacancy();
      getPersonalClient();
    }
  }, []);

  React.useEffect(() => {
    if (vacancy) getVacancy(vacancy);
  }, [vacancy]);

  React.useEffect(() => {
    if (client) getPersonalClient(client);
  }, [client]);

  return (
    <Modal
      modalTitle={`${currentMatch ? 'Criar Match' : 'Match'}`}
      onClose={handleOnClose}
      hideButton
      maxWidth={981}
      buttonCallback={() => {
        null;
      }}>
      {!currentMatch && (
        <SearchArea>
          <Search
            placeholder="Pesquisar cliente"
            value={client}
            setValue={setClient}
            customStyle={{ maxWidth: 294, backgroundColor: '#E4E4E4' }}
          />

          <Search
            placeholder="Pesquisar Vaga"
            value={vacancy}
            setValue={setVacancy}
            customStyle={{ maxWidth: 339, backgroundColor: '#E4E4E4' }}
          />
        </SearchArea>
      )}
      <Content>
        <CardUserJob
          isAlternative
          customStyle={{ margin: 0 }}
          client={currentPersonalClient}
        />
        <ChosenArea>
          <ChosenLine />
          {!currentMatch ? (
            <ChosenButton
              accept
              onClick={() =>
                handleCreate(currentVacancy, currentPersonalClient)
              }>
              Criar
            </ChosenButton>
          ) : (
            <>
              <ChosenButton onClick={deleteCallback}>Negar</ChosenButton>
              <ChosenLine />
              <ChosenButton accept onClick={() => handleUpdate(true)}>
                Aceitar
              </ChosenButton>
            </>
          )}
          <ChosenLine />
        </ChosenArea>
        <VacanciesCard onClick={() => null}>
          <VacancieNumber>Código da vaga: {currentVacancy?.id}</VacancieNumber>
          <VacancieInfoRow>
            <VacancieName>{currentVacancy?.name}</VacancieName>
            <VacancieInfoSvgRow>
              <MatchIcon />
              <VacancieMatchs>matchs</VacancieMatchs>
            </VacancieInfoSvgRow>
          </VacancieInfoRow>
          <VacancieCategory>{currentVacancy?.occupation}</VacancieCategory>
          <VacancieInfoSvgRow style={{ marginBottom: 25 }}>
            <AmountIcon />
            <VacancieAmount>
              {currentVacancy?.quantity}
              vagas
            </VacancieAmount>
          </VacancieInfoSvgRow>
          <VacancieInfoRow>
            <VacancieInfoSvgRow>
              <MoneyIcon />
              <VacancieSalary>
                R$
                {currentVacancy?.budget}
              </VacancieSalary>
            </VacancieInfoSvgRow>
            {currentVacancy?.workplace && (
              <VacancieInfoSvgRow>
                <PinIcon />
                <VacancieLocality>{currentVacancy?.workplace}</VacancieLocality>
              </VacancieInfoSvgRow>
            )}
          </VacancieInfoRow>
        </VacanciesCard>
      </Content>
    </Modal>
  );
};

export default MatchModal;

{
  /* <InputSelectComponent
customContainerSize="full"
value={name}
setValue={setName}
placeholder={'Nome da Linguagem' || language?.language_name}
warning={warning.name}
customStyle={{ background: '#fff' }}
selectData={CONST_LANGUAGES}
/>
<Separator />
<InputSelectComponent
customContainerSize="full"
placeholder={'Nivel da Linguagem' || language?.language_level}
value={level}
setValue={setLevel}
customStyle={{ background: '#fff' }}
warning={warning.level}
selectData={CONST_LANGUAGES_LEVEL}
/> */
}
