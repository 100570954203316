import React from 'react';
import typ from './index.d'
const ResponsiveContainer : React.FC<typ.Props> = ({children}) => {
   return (
    <div className='container'>
      {children}
    </div>
   );
}

export default ResponsiveContainer ;