import { configureStore, Reducer } from '@reduxjs/toolkit';
import { reducerManager } from './manager';
import { reducers } from './reducers';

const store = configureStore({
  reducer: reducerManager,
  middleware: getDefaultMiddleware => {
    const middleware = getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });
    return middleware;
  },
});


export { store };

export type RootState = ReturnType<typeof reducers>;
export type RootStore = typeof store;
