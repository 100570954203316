import { axiosManager } from '../../axios-manager';
import ApplicantCRUDProps from './index.d';

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzX2luIjoiMjAyNC0xMC0wNyAxOTo0NzoxNC4yNzMzNTQiLCJjcmVhdGVkX2F0IjoiMjAyNC0wOS0wNyAxOTo0NzoxNC4yNzMzODciLCJ1c2VybmFtZSI6Im1hcmNvbmUiLCJ1c2VyX2lkIjoxfQ.3WAr8uTpAGbcvrMvy2xn_0OB3LftDxlzqG83A2L03bg';

class ApplicantCRUD {
  create({
    budget,
    email,
    local,
    name,
    occupation,
    attachment,
    image,
    kanban_step = 'VACANCY_OPENING',
  }: ApplicantCRUDProps.createParams) {
    return axiosManager<ApplicantCRUDProps.createReturn>({
      method: 'post',
      url: `/applicant/create`,
      auth: token,
      payload: {
        name,
        email,
        local,
        occupation,
        budget,
        kanban_step,
      },
    });
  }

  read({ page = 1, per_page = 40 }: ApplicantCRUDProps.readParams) {
    let url = `/applicant/list?page=${page}&per_page=${per_page}`;

    return axiosManager<ApplicantCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: token,
      payload: null,
    });
  }

  update({
    budget,
    email,
    local,
    name,
    occupation,
    image,
    attachment,
    kanban_step,
    id,
  }: ApplicantCRUDProps.updateParams) {
    return axiosManager<ApplicantCRUDProps.updateReturn>({
      method: 'put',
      url: `/talent-pool/update/${id}`,
      auth: token,
      payload: {
        name,
        email,
        local,
        occupation,
        budget,
        kanban_step,
      },
    });
  }

  delete({ id }: ApplicantCRUDProps.deleteParams) {
    return axiosManager<ApplicantCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/applicant/delete/${id}`,
      auth: token,
      payload: null,
    });
  }

  view({ id }: ApplicantCRUDProps.viewParams) {
    return axiosManager<ApplicantCRUDProps.viewReturn>({
      method: 'get',
      url: `/applicant/attachment/view/16`,
      auth: token,
      payload: null,
    });
  }
}

export { ApplicantCRUD };
