import React from 'react';
import {
  CheckBok,
  Container,
  Description,
  DescriptionArea,
  Elipse,
  ElipseMobile,
  ForgottPass,
  InfoArea,
  InputArea,
  LoginArea,
  LoginTitle,
  Logo,
  RememberMe,
  RememberMeArea,
} from './styles';
import InputComponent from '../../../components/input';
import Button from '../../../components/button';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import { LoginCRUD } from '../../../services/requests/login';
import { useAppActions } from '../../../hooks/useRedux';
import Load from '../../../components/load';
import Cookies from 'js-cookie';

const loginCrud = new LoginCRUD();

const LoginPage: React.FC = () => {
  const { signIn, setCurrentUser } = useAppActions();

  const { colors } = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);
  const [mail, setMail] = React.useState('');
  const [pass, setPass] = React.useState('');
  const [warning, setWarning] = React.useState({
    email: false,
    password: false,
  });

  const handleLogin = React.useCallback(
    async (email: string, password: string) => {
      try {
        if (!email && !password) {
          setWarning({
            email: true,
            password: true,
          });
          toast('Digite todos os campos.');
          return;
        }
        if (!email) {
          setWarning(e => ({ ...e, email: true }));
          toast('Digite o email.');
          return;
        }
        if (!password) {
          setWarning(e => ({ ...e, password: true }));
          toast('Digite a senha.');
          return;
        }
        setIsLoading(true);

        const { data, status, error } = await loginCrud.login({
          email,
          password,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200 && data.token) {
          Cookies.set('token', data.token, {
            secure: true,
            sameSite: 'strict',
          });
          Cookies.set('id', data.id.toString(), {
            secure: true,
            sameSite: 'strict',
          });
          toast('Usuario Logado com  sucesso.');
          setCurrentUser({ id: data.id.toString(), token: data.token });
          signIn();
        }
      } catch (e) {
        alert(e);
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );
  return (
    <Container>
      <ElipseMobile
        elipseColor={colors.secondary}
        style={{ position: 'absolute', top: -60, right: -80 }}
      />
      <ElipseMobile
        elipseColor={colors.primaryLight}
        style={{ position: 'absolute', bottom: -60, left: -80 }}
      />
      <DescriptionArea>
        <Elipse
          elipseColor={colors.secondary}
          style={{ position: 'absolute', top: 0, right: 0 }}
        />
        <Description>
          O melhor ambiente para gerenciar seus clientes
        </Description>
        <Elipse
          elipseColor={'#F6C9F7'}
          style={{ position: 'absolute', bottom: -60, left: -40 }}
        />
      </DescriptionArea>
      <LoginArea>
        {isLoading ? (
          <>
            <Load size="extraLarge" customStyle={{ color: colors.primary }} />
          </>
        ) : (
          <>
            <Logo />
            <InputArea>
              <LoginTitle>Entrar</LoginTitle>
              <InputComponent
                value={mail}
                setValue={setMail}
                placeholder="Email"
                type="email"
                customStyle={{ marginBottom: 20 }}
                warning={warning.email}
              />
              <InputComponent
                value={pass}
                setValue={setPass}
                placeholder="Senha"
                type="password"
                customStyle={{ marginBottom: 20 }}
                warning={warning.password}
              />
            </InputArea>
            <InfoArea>
              <RememberMeArea>
                <CheckBok />
                <RememberMe>Lembrar de mim</RememberMe>
              </RememberMeArea>
              <ForgottPass onClick={() => alert('as')}>
                Esqueceu a senha?
              </ForgottPass>
            </InfoArea>
            <Button title="Enviar" callback={() => handleLogin(mail, pass)} />
          </>
        )}
      </LoginArea>
    </Container>
  );
};

export default LoginPage;
