import styled from 'styled-components';
import _, { StylesProps } from '../input/index.d';

export const Container = styled.div<StylesProps.containerSizesProps>`
  width: ${({ sizes }) => {
    switch (sizes) {
      case 'half':
        return 'calc(100% / 2 - 10px)';
      case 'tird':
        return 'calc(100% / 3 - 20px)';
      case 'full':
      default:
        return '100%';
    }
  }};
  position: relative;
`;
export const Select = styled.select<StylesProps.inputStatusProps>`
  width: 100%;
  background-color: #f9f9f9;
  border: 1px solid
    ${({ theme, status }) => {
      switch (status) {
        case 'warning':
          return theme.colors.red;
        case 'full':
          return theme.colors.secondary;
        case 'empty':
        default:
          return theme.colors.grayDark;
      }
    }};
  border-radius: 5px;
  padding: 12px 7px;
  @media only screen and (min-width: 480px) {
    padding: 13px 8px;
  }
  @media only screen and (min-width: 991px) {
    padding: 15px 10px;
  }
`;

export const InputLabel = styled.label<{ showLabel?: boolean }>`
  display: block;
  position: absolute;
  width: fit-content;
  z-index: 2;
  top: -14px;
  left: 10px;
  font-size: 13px;
  padding: 3px;
  opacity: ${({ showLabel }) => (showLabel ? 1 : 0)};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.secondary};
  transition: opacity 350ms ease;
`;
