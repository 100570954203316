import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  AddVancacieLink,
  AmountIcon,
  BackFullIcon,
  Container,
  Content,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  LoadRow,
  MatchIcon,
  MoneyIcon,
  NextPageButton,
  PaginationArea,
  PinIcon,
  PreviousPageButton,
  VacancieAmount,
  VacancieCategory,
  VacancieInfoRow,
  VacancieInfoSvgRow,
  VacancieLocality,
  VacancieMatchs,
  VacancieName,
  VacancieNumber,
  VacancieSalary,
  VacanciesCard,
  VacanciesContainer,
} from './styles';
import MenuBar from '../../../components/menu-bar';
import ResponsiveContainer from '../../../components/container';
import Search from '../../../components/search';
import { VancancieCRUD } from '../../../services/requests/vancacies';
import VancancieCRUDProps, {
  VancacieData,
} from '../../../services/requests/vancacies/index.d';
import Load from '../../../components/load';
import { useTheme } from 'styled-components';
import VancancieModal from '../../../components/modais/vancancie';
import WarnModal from '../../../components/modais/warn';
import { toast } from 'react-toastify';

const vancacieCRUD = new VancancieCRUD();

const VacanciesPage: React.FC = () => {
  const { colors } = useTheme();
  // Ref
  const currentPage = React.useRef(1);

  // States
  const [search, setSearch] = React.useState('');
  const [vancacies, setVancacies] =
    React.useState<VancancieCRUDProps.readReturn>();
  const [currentVancacie, setCurrentVancacie] = React.useState<VancacieData>();
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showWarn, setShowWarn] = React.useState(false);

  // Callbacks

  const refreshData = React.useCallback(async (page?: number) => {
    try {
      setLoading(true);
      currentPage.current = page || 1;
      const { data, status, error } = await vancacieCRUD.read({
        page,
        per_page: 9,
      });

      console.log(data);

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setVancacies(data);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteVancicie = React.useCallback(async (id: number) => {
    try {
      const { data, status, error } = await vancacieCRUD.delete({ id });

      if (error) throw new Error('Erro ao deletar usuario.');

      if (status === 200) {
        toast('Vaga deletada com sucesso.');
        refreshData();
      }
    } catch (e) {
      toast(e as String);
    }
  }, []);

  const handleOpenModal = React.useCallback((currentVancacie: VancacieData) => {
    setCurrentVancacie(currentVancacie);
    setShowModal(true);
  }, []);

  const handleCloseModal = React.useCallback(() => {
    setCurrentVancacie(undefined);
    setShowModal(false);
  }, []);

  const currentData = React.useMemo(() => {
    if (search) {
      let currentFilter = search.toLocaleLowerCase();
      return vancacies?.Vacancy?.filter(e =>
        e.name.toLowerCase().includes(currentFilter),
      );
    }
    return vancacies?.Vacancy;
  }, [vancacies, search]);

  React.useEffect(() => {
    refreshData();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Vagas" />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Todas as vagas</HeaderTitle>
              <HeaderActions>
                <Search
                  value={search}
                  setValue={setSearch}
                  placeholder="Pesquisar vagas"
                />
                <AddVancacieLink href="/criar-vaga">
                  <AddConsultantButton
                    title="Adicionar vaga"
                    iconRight={AddIcon}
                  />
                </AddVancacieLink>
              </HeaderActions>
            </HeaderInfo>
          </Header>
          <VacanciesContainer>
            {loading ? (
              <LoadRow>
                <Load
                  customStyle={{
                    color: colors.secondaryDark,
                  }}
                  size="extraLarge"
                />
              </LoadRow>
            ) : (
              <>
                {currentData && currentData?.length > 0 ? (
                  currentData?.map((e, i) => {
                    return (
                      <VacanciesCard key={i} onClick={() => handleOpenModal(e)}>
                        <VacancieNumber>Código da vaga: {e.id}</VacancieNumber>
                        <VacancieInfoRow>
                          <VacancieName>{e.name}</VacancieName>
                          <VacancieInfoSvgRow>
                            <MatchIcon />
                            <VacancieMatchs>matchs</VacancieMatchs>
                          </VacancieInfoSvgRow>
                        </VacancieInfoRow>
                        <VacancieCategory>{e.occupation}</VacancieCategory>
                        <VacancieInfoSvgRow style={{ marginBottom: 25 }}>
                          <AmountIcon />
                          <VacancieAmount>{e.quantity} vagas</VacancieAmount>
                        </VacancieInfoSvgRow>
                        <VacancieInfoRow>
                          <VacancieInfoSvgRow>
                            <MoneyIcon />
                            <VacancieSalary>R$ {e.budget}</VacancieSalary>
                          </VacancieInfoSvgRow>
                          {e.workplace && (
                            <VacancieInfoSvgRow>
                              <PinIcon />
                              <VacancieLocality>{e.workplace}</VacancieLocality>
                            </VacancieInfoSvgRow>
                          )}
                        </VacancieInfoRow>
                      </VacanciesCard>
                    );
                  })
                ) : (
                  <HeaderTitle>Nenhuma vaga encontrada...</HeaderTitle>
                )}
                <PaginationArea>
                  {vancacies?.has_prev && (
                    <PreviousPageButton
                      title="Voltar Página"
                      callback={() => refreshData(currentPage.current - 1)}
                    />
                  )}
                  {vancacies?.has_next && (
                    <NextPageButton
                      title="Proxima Página"
                      callback={() => refreshData(currentPage.current + 1)}
                    />
                  )}
                </PaginationArea>
              </>
            )}
          </VacanciesContainer>
        </ResponsiveContainer>
      </Content>
      {showModal && currentVancacie && (
        <VancancieModal
          onClose={handleCloseModal}
          vancacie={currentVancacie}
          refreshData={refreshData}
          deleteCallback={() => setShowWarn(true)}
        />
      )}
      {showWarn && (
        <WarnModal
          title="Remover vaga"
          description={`Certeza que quer remover a vaga ${currentVancacie?.name}?`}
          confirmCallback={() => {
            deleteVancicie(currentVancacie?.id || 0);
            handleCloseModal();
            setShowWarn(false);
          }}
          declineCallback={() => {
            setCurrentVancacie(undefined);
            setShowWarn(false);
          }}
        />
      )}
    </Container>
  );
};

export default VacanciesPage;
