import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  BackFullIcon,
  CheckIcon,
  CloseIcon,
  Container,
  Content,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  LoadRow,
  MatchCard,
  MatchCardClickArea,
  MatchContainer,
  MatchDeleteButton,
  MatchDescriptionRow,
  MatchDescriptionText,
  MatchPhoto,
  NextPageButton,
  PaginationArea,
  PreviousPageButton,
} from './styles';
import MenuBar from '../../../components/menu-bar';
import ResponsiveContainer from '../../../components/container';
import Search from '../../../components/search';
import { toast } from 'react-toastify';
import MatchModal from '../../../components/modais/match';
import MatchCRUDProps, {
  MatchData,
} from '../../../services/requests/matchs/index.d';
import { MatchCRUD } from '../../../services/requests/matchs';
import { useParams } from 'react-router-dom';
import Load from '../../../components/load';
import { useTheme } from 'styled-components';
import WarnModal from '../../../components/modais/warn';

const matchCRUD = new MatchCRUD();

const MatchsPage: React.FC = () => {
  const { id } = useParams();
  const { colors } = useTheme();

  // Ref
  const currentPage = React.useRef(1);

  // States
  const [search, setSearch] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [matchs, setMatchs] = React.useState<MatchCRUDProps.readReturn>();
  const [currentMatch, setCurrentMatch] = React.useState<MatchData>();
  const [showWarnModal, setShowWarnModal] = React.useState(false);

  // Callbacks
  const getMatchs = React.useCallback(async (page?: number) => {
    try {
      setLoading(true);
      currentPage.current = page || 1;
      const { data, status, error } = await matchCRUD.read({
        page,
        per_page: 9,
        vacancy_id: Number(id),
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setMatchs(data);
      }
    } catch (e) {
      // alert(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteMatch = React.useCallback(async (id: number) => {
    try {
      const { data, status, error } = await matchCRUD.delete({ id });

      if (error) throw new Error('Erro ao deletar usuario.');

      if (status === 200) {
        toast('Match deletado com sucesso.');
        getMatchs();
      }
    } catch (e) {
      toast(e as String);
    }
  }, []);

  const currentData = React.useMemo(() => {
    if (search) {
      let currentFilter = search.toLocaleLowerCase();
      return matchs?.VacancyMatch?.filter(
        e =>
          e.personal_client_relation.name
            .toLowerCase()
            .includes(currentFilter) ||
          e.advisor_relation.name.toLowerCase().includes(currentFilter),
      );
    }
    return matchs?.VacancyMatch;
  }, [matchs, search]);

  React.useEffect(() => {
    getMatchs();
  }, []);

  return (
    <Container>
      <MenuBar />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack>
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Todos os matchs</HeaderTitle>
              <HeaderActions>
                <Search
                  value={search}
                  setValue={setSearch}
                  placeholder="Pesquisar match"
                />
                <AddConsultantButton
                  title="Criar match"
                  iconRight={AddIcon}
                  callback={() => setShowModal(true)}
                />
              </HeaderActions>
            </HeaderInfo>
          </Header>
          <MatchContainer>
            <MatchDescriptionRow>
              <MatchDescriptionText style={{ flex: 3 }}>
                Cliente
              </MatchDescriptionText>
              <MatchDescriptionText style={{ flex: 4 }}>
                Consultor
              </MatchDescriptionText>
              <MatchDescriptionText style={{ flex: 3 }}>
                Criado em
              </MatchDescriptionText>
              <MatchDescriptionText style={{ flex: 0 }}>
                Status
              </MatchDescriptionText>
            </MatchDescriptionRow>

            {loading ? (
              <LoadRow>
                <Load
                  customStyle={{
                    color: colors.secondaryDark,
                  }}
                  size="extraLarge"
                />
              </LoadRow>
            ) : (
              <>
                {currentData && currentData?.length > 0 ? (
                  currentData?.map((e, i) => {
                    return (
                      <MatchCard key={i}>
                        <MatchCardClickArea
                          onClick={() => {
                            setCurrentMatch(e);
                            setShowModal(true);
                          }}>
                          <MatchPhoto
                            src={
                              e.personal_client_relation.image ||
                              require('../../../assets/temp/temp-user.png')
                            }
                          />
                          <MatchDescriptionText style={{ flex: 3 }}>
                            {e.personal_client_relation.name}
                          </MatchDescriptionText>
                          <MatchPhoto
                            src={
                              e.advisor_relation.image ||
                              require('../../../assets/temp/temp-user.png')
                            }
                          />
                          <MatchDescriptionText style={{ flex: 4 }}>
                            {e.advisor_relation.name}
                          </MatchDescriptionText>
                          <MatchDescriptionText style={{ flex: 3.5 }}>
                            {e.created_at}
                          </MatchDescriptionText>
                        </MatchCardClickArea>
                        {e.authorized ? <CheckIcon /> : <CloseIcon />}
                      </MatchCard>
                    );
                  })
                ) : (
                  <HeaderTitle>Nenhum match encontrado...</HeaderTitle>
                )}
                <PaginationArea>
                  {matchs?.has_prev && (
                    <PreviousPageButton
                      title="Voltar Página"
                      callback={() => getMatchs(currentPage.current - 1)}
                    />
                  )}
                  {matchs?.has_next && (
                    <NextPageButton
                      title="Proxima Página"
                      callback={() => getMatchs(currentPage.current + 1)}
                    />
                  )}
                </PaginationArea>
              </>
            )}
          </MatchContainer>
        </ResponsiveContainer>
      </Content>
      {showModal && (
        <MatchModal
          onClose={() => {
            getMatchs();
            setCurrentMatch(undefined);
            setShowModal(false);
          }}
          match={currentMatch}
          deleteCallback={() => setShowWarnModal(true)}
        />
      )}
      {showWarnModal && (
        <WarnModal
          title="Apagar Match"
          description="Tem certeza que deseja apagar esse match ?"
          declineCallback={() => setShowWarnModal(false)}
          confirmCallback={() => {
            deleteMatch(currentMatch?.id || 0);
            setShowWarnModal(false);
            setShowModal(false);
            getMatchs();
          }}
        />
      )}
    </Container>
  );
};

export default MatchsPage;
