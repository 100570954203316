import React from 'react';
import {
  BackFullIcon,
  ButtonComp,
  Card,
  CardColumn,
  CardDescriptionLabel,
  CardDescriptionTitle,
  CardRow,
  CardRowForSvg,
  CardsColumns,
  CardSectionColumn,
  CardSectionRow,
  CardSectionTitle,
  CardSeparator,
  ConsultantImg,
  Container,
  ContainerCards,
  Content,
  CpfIcon,
  EditIcon,
  GraduationBold,
  GraduationCard,
  GraduationIcons,
  GraduationRow,
  GraduationText,
  Header,
  HeaderBack,
  KeyWord,
  KeyWordsArea,
  Language,
  LanguageRow,
  LoadingIndicator,
  MatchCard,
  MatchIcon,
  MatchJob,
  MatchName,
  Name,
  Occupation,
  Responsive,
  SvgRow,
  TrashIcon,
  UserImg,
} from './styles';
import { VancancieCRUD } from '../../../../services/requests/vancacies';
import { VancacieData } from '../../../../services/requests/vancacies/index.d';
import Load from '../../../../components/load';
import { useTheme } from 'styled-components';
import MenuBar from '../../../../components/menu-bar';
import ResponsiveContainer from '../../../../components/container';
import { useNavigate, useParams } from 'react-router-dom';
import { LanguageCRUD } from '../../../../services/requests/language';
import { GraduationCRUD } from '../../../../services/requests/graduation';
import LanguageModal from '../../../../components/modais/language';
import Button from '../../../../components/button';
import { LanguageData } from '../../../../services/requests/language/index.d';
import {
  capitalizeFirstLetter,
  formatCPF,
  limitarString,
} from '../../../../utils/convert';
import { toast } from 'react-toastify';
import GraduationModal from '../../../../components/modais/graduation';
import { GraduationData } from '../../../../services/requests/graduation/index.d';
import { LoadRow } from '../styles';
import { theme } from '../../../../global/styles';
import { MatchCRUD } from '../../../../services/requests/matchs';
import MatchCRUDProps from '../../../../services/requests/matchs/index.d';

const vancacieCRUD = new VancancieCRUD();
const languageCRUD = new LanguageCRUD();
const graduationCRUD = new GraduationCRUD();
const matchCRUD = new MatchCRUD();

const VisualizateVancacie: React.FC = () => {
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { id } = useParams();

  // Ref
  const currentPage = React.useRef(1);

  // States
  const [currentVancacie, setCurrentVancacie] = React.useState<VancacieData>();
  const [loading, setLoading] = React.useState(false);

  const [languages, setLanguages] = React.useState<LanguageData[]>();
  const [loadingLanguages, setLoadingLanguages] = React.useState(false);

  const [graduation, setGraduation] = React.useState<GraduationData[]>();
  const [currentGraduation, setCurrentGraduation] =
    React.useState<GraduationData>();
  const [loadingGraduation, setLoadingGraduation] = React.useState(false);

  const [showModalLanguage, setShowModalLanguage] = React.useState(false);
  const [showModalGraduation, setShowModalGraduation] = React.useState(false);

  const [matchs, setMatchs] = React.useState<MatchCRUDProps.readReturn>();

  // Callbacks
  const refreshData = React.useCallback(async (page?: number) => {
    try {
      setLoading(true);
      currentPage.current = page || 1;
      const { data, status, error } = await vancacieCRUD.read({
        id: id,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setCurrentVancacie(data.Vacancy[0]);
      }
    } catch (e) {
      alert(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, []);

  const getLanguages = React.useCallback(async () => {
    try {
      setLoadingLanguages(true);
      const { data, status, error } = await languageCRUD.read({
        isVancacie: true,
        id: Number(id),
      });

      if (error) throw new Error(error.message.description);

      if (status === 200 && data?.Language) {
        setLanguages(data.Language);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoadingLanguages(false);
    }
  }, []);

  const handleDeleteLanguage = React.useCallback(async (id: number) => {
    try {
      setLoadingLanguages(true);
      const { data, status, error } = await languageCRUD.delete({
        id: id,
      });

      if (error) throw new Error(error.message.description);

      if (status === 200) {
        getLanguages();
        toast('Linguagem deletada com sucesso.');
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  const getGraduations = React.useCallback(async () => {
    try {
      setLoadingGraduation(true);
      const { data, status, error } = await graduationCRUD.read({
        isVancacie: true,
        id: Number(id),
      });

      if (error) throw new Error(error.message.description);

      if (status === 200 && data?.Graduation) {
        setGraduation(data.Graduation);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoadingGraduation(false);
    }
  }, []);

  const handleDeleteGraduations = React.useCallback(async (id: number) => {
    try {
      setLoadingGraduation(true);
      const { data, status, error } = await graduationCRUD.delete({
        id: id,
      });

      if (error) throw new Error(error.message.description);

      if (status === 200) {
        getGraduations();
        toast('Formação deletada com sucesso.');
      }
    } catch (e) {
      alert(e);
    }
  }, []);

  const getMatchs = React.useCallback(async (page?: number) => {
    try {
      const { data, status, error } = await matchCRUD.read({
        page,
        per_page: 3,
        vacancy_id: Number(id),
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setMatchs(data);
      }
    } catch (e) {
      alert(e);
    } finally {
      setTimeout(() => {}, 3000);
    }
  }, []);

  // Effect
  React.useEffect(() => {
    refreshData();
    getLanguages();
    getGraduations();
    getMatchs();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Vagas" />
      <Content>
        <Responsive>
          <Header>
            <HeaderBack href="/">
              <BackFullIcon />
            </HeaderBack>
          </Header>
          <ContainerCards>
            <CardsColumns maxWidth={380}>
              <Card>
                <CardSectionRow>
                  <CardSectionTitle size="huge">Dados</CardSectionTitle>
                </CardSectionRow>
                <Name>{currentVancacie?.name}</Name>
                <Occupation>{currentVancacie?.occupation}</Occupation>
                <CardSeparator />

                <CardSectionRow>
                  <CardSectionTitle size="normal">Detalhes</CardSectionTitle>

                  <ButtonComp onClick={() => navigate(`/editar-vaga/${id}`)}>
                    <EditIcon />
                  </ButtonComp>
                </CardSectionRow>

                <CardDescriptionTitle>Código da vaga</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentVancacie?.id}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Número de vagas</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentVancacie?.quantity}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Nivel hierarquico</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentVancacie?.level}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Área de atuação</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentVancacie?.occupation}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Salário</CardDescriptionTitle>
                <CardDescriptionLabel>
                  R$ {currentVancacie?.budget}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Sexo</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentVancacie?.sexual_gender}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Local de trabalho</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentVancacie?.workplace}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Palavras Chave</CardDescriptionTitle>
                {currentVancacie?.key_words &&
                  currentVancacie?.key_words?.length > 0 && (
                    <KeyWordsArea>
                      {currentVancacie?.key_words.map((e, i) => (
                        <KeyWord>{e}</KeyWord>
                      ))}
                    </KeyWordsArea>
                  )}
              </Card>
            </CardsColumns>
            <CardsColumns>
              <Card>
                <CardSectionRow>
                  <CardSectionTitle size="normal">Contato</CardSectionTitle>

                  <ButtonComp onClick={() => navigate(`/editar-vaga/${id}`)}>
                    <EditIcon />
                  </ButtonComp>
                </CardSectionRow>

                <CardSectionRow style={{ marginBottom: 47 }}>
                  <CardSectionColumn>
                    <CardDescriptionTitle>Nome</CardDescriptionTitle>
                    <CardDescriptionLabel>
                      {currentVancacie?.contact_name}
                    </CardDescriptionLabel>
                  </CardSectionColumn>

                  <CardSectionColumn>
                    <CardDescriptionTitle>Telefone</CardDescriptionTitle>
                    <CardDescriptionLabel>
                      {currentVancacie?.contact_phone}
                    </CardDescriptionLabel>
                  </CardSectionColumn>
                </CardSectionRow>

                <CardDescriptionTitle>Cargo</CardDescriptionTitle>
                <CardDescriptionLabel style={{ marginBottom: 47 }}>
                  {currentVancacie?.contact_role}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Email</CardDescriptionTitle>
                <CardDescriptionLabel style={{ marginBottom: 47 }}>
                  {currentVancacie?.email}
                </CardDescriptionLabel>
              </Card>
              <Card>
                <CardSectionRow>
                  <CardSectionTitle size="normal">
                    Profissional
                  </CardSectionTitle>

                  <ButtonComp onClick={() => navigate(`/editar-vaga/${id}`)}>
                    <EditIcon />
                  </ButtonComp>
                </CardSectionRow>
                <CardSectionRow>
                  <CardSectionTitle size="normal">Detalhes</CardSectionTitle>
                </CardSectionRow>

                <CardDescriptionTitle>Beneficios</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentVancacie?.benefits}
                </CardDescriptionLabel>

                <CardDescriptionTitle>Perfil</CardDescriptionTitle>
                <CardDescriptionLabel>
                  {currentVancacie?.perfil}
                </CardDescriptionLabel>
              </Card>
            </CardsColumns>
            <CardsColumns minWidth={400}>
              <Card>
                <CardSectionRow>
                  <CardSectionTitle size="normal">Idioma</CardSectionTitle>
                </CardSectionRow>
                {loadingLanguages ? (
                  <LoadRow>
                    <LoadingIndicator
                      customStyle={{
                        width: 40,
                        height: 40,
                        color: theme.colors.secondary,
                        marginBottom: 12,
                      }}
                      size="large"
                    />
                  </LoadRow>
                ) : (
                  <>
                    {languages && languages.length > 0 ? (
                      languages?.map(e => (
                        <LanguageRow>
                          <Language>
                            {capitalizeFirstLetter(e.language_name)}
                          </Language>
                          <Language>
                            {capitalizeFirstLetter(e.language_level)}
                          </Language>
                          <ButtonComp
                            onClick={() => handleDeleteLanguage(e.id)}>
                            <TrashIcon />
                          </ButtonComp>
                        </LanguageRow>
                      ))
                    ) : (
                      <CardSectionTitle
                        style={{ marginBottom: 12 }}
                        size="normal">
                        Nenhum idioma cadastrado...
                      </CardSectionTitle>
                    )}
                  </>
                )}
                <Button
                  title="Novo Idioma"
                  callback={() => {
                    if (!showModalLanguage) setShowModalLanguage(e => !e);
                  }}
                />
              </Card>
              <Card alternative>
                <CardSectionRow>
                  <CardSectionTitle size="normal">Formação</CardSectionTitle>
                </CardSectionRow>
                {loadingGraduation ? (
                  <LoadRow>
                    <LoadingIndicator
                      customStyle={{
                        width: 40,
                        height: 40,
                        color: theme.colors.secondary,
                        marginBottom: 12,
                      }}
                      size="large"
                    />
                  </LoadRow>
                ) : (
                  <>
                    {graduation && graduation.length > 0 ? (
                      graduation?.map(e => (
                        <GraduationCard>
                          <GraduationRow>
                            <GraduationBold>
                              {e.institution_name} - {e.graduation_type}
                            </GraduationBold>
                            <GraduationIcons>
                              <ButtonComp
                                onClick={() => {
                                  setCurrentGraduation(e);
                                  setShowModalGraduation(true);
                                }}>
                                <EditIcon />
                              </ButtonComp>
                              <ButtonComp
                                onClick={() => handleDeleteGraduations(e.id)}>
                                <TrashIcon />
                              </ButtonComp>
                            </GraduationIcons>
                          </GraduationRow>
                          <GraduationText style={{ marginTop: 0 }}>
                            {e.graduation_name} | {e.graduation_time}
                          </GraduationText>
                          <GraduationText>
                            {e.graduation_description}
                          </GraduationText>
                        </GraduationCard>
                      ))
                    ) : (
                      <CardSectionTitle
                        style={{ marginBottom: 12 }}
                        size="normal">
                        Nenhuma formação cadastrada...
                      </CardSectionTitle>
                    )}
                  </>
                )}
                <Button
                  callback={() => {
                    if (!showModalGraduation) setShowModalGraduation(true);
                  }}
                  title="Nova Formação"
                />
              </Card>
            </CardsColumns>
            <CardsColumns minWidth={381}>
              <Card alternative maxHeight={390}>
                <CardSectionRow>
                  <CardSectionTitle size="normal">Matchs</CardSectionTitle>
                  <SvgRow>
                    <MatchIcon />
                  </SvgRow>
                </CardSectionRow>
                {loadingGraduation ? (
                  <LoadRow>
                    <LoadingIndicator
                      customStyle={{
                        width: 40,
                        height: 40,
                        color: theme.colors.secondary,
                        marginBottom: 12,
                      }}
                      size="large"
                    />
                  </LoadRow>
                ) : (
                  <>
                    {matchs?.VacancyMatch?.map(e => (
                      <MatchCard>
                        <CardRowForSvg>
                          <UserImg
                            src={
                              e.personal_client_relation.image ||
                              require('../../../../assets/temp/temp-user.png')
                            }
                          />
                          <CardColumn>
                            <MatchName>
                              {e.personal_client_relation.name}
                            </MatchName>
                            <MatchJob>
                              {e.personal_client_relation.occupation}
                            </MatchJob>
                          </CardColumn>
                        </CardRowForSvg>
                        <CardRow>
                          <CardRowForSvg>
                            <CpfIcon />
                            <MatchName>
                              {formatCPF(e.personal_client_relation.cpf)}
                            </MatchName>
                          </CardRowForSvg>
                          <ConsultantImg
                            src={
                              e.advisor_relation.image ||
                              require('../../../../assets/temp/temp-user.png')
                            }
                          />
                        </CardRow>
                      </MatchCard>
                    ))}
                  </>
                )}
              </Card>
            </CardsColumns>
          </ContainerCards>
        </Responsive>
      </Content>
      {showModalLanguage && (
        <LanguageModal
          onClose={() => {
            getLanguages();
            setShowModalLanguage(false);
          }}
          vacancyId={currentVancacie?.id}
        />
      )}
      {showModalGraduation && (
        <GraduationModal
          onClose={() => {
            setCurrentGraduation(undefined);
            getGraduations();
            setShowModalGraduation(false);
          }}
          graduationId={currentVancacie?.id}
          graduation={currentGraduation}
        />
      )}
    </Container>
  );
};

export default VisualizateVancacie;
