import React, { LegacyRef } from 'react';

import typ from './index.d';
import {
  DocumentArea,
  DocumentButton,
  DocumentIcon,
  DocumentInput,
  Image,
  ImageArea,
  ImageInput,
  Modal,
  PhotoIcon,
} from './styles';
import { TitleSection } from '../../modal/styles';
import InputComponent from '../../input';
import EmptyImage from '../../../assets/images/empty-image.png';
import { AdvisorCRUD } from '../../../services/requests/advisor';
import { toast } from 'react-toastify';

const advisorCRUD = new AdvisorCRUD();

const ConsultantModal: React.FC<typ.Props> = ({ onClose, consultantData }) => {
  // Refs
  const inputImageRef = React.useRef<HTMLInputElement>(null);

  // States
  const [img, setImg] = React.useState(consultantData?.image || '');
  const [name, setName] = React.useState(consultantData?.name || '');
  const [mail, setMail] = React.useState(consultantData?.email || '');
  const [pass, setPass] = React.useState('');
  const [imageBase64, setImageBase64] = React.useState<any>();
  const [warning, setWarning] = React.useState({
    name: false,
    mail: false,
    pass: false,
  });

  const createConsultant = React.useCallback(
    async (name: string, mail: string, pass: string, image: any) => {
      try {
        if (!name && !mail && !pass) {
          setWarning({ mail: true, name: true, pass: true });
          toast('Digite os campos corretamente.');
          return;
        }
        if (!name) {
          setWarning(e => ({ ...e, name: true }));
          toast('Digite o nome.');
          return;
        }
        if (!mail) {
          setWarning(e => ({ ...e, mail: true }));
          toast('Digite o email.');
          return;
        }
        if (!mail.includes('@')) {
          toast('Digite o email corretamente');
          setWarning(e => ({ ...e, mail: true }));
          return;
        }
        if (!pass) {
          setWarning(e => ({ ...e, pass: true }));
          toast('Digite a senha.');
          return;
        }

        const { data, status, error } = await advisorCRUD.create({
          name,
          mail,
          pass,
          photo: image,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Consultor criado com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );
  const editConsultant = React.useCallback(
    async (name: string, mail: string, pass: string, image: string) => {
      try {
        if (!mail.includes('@')) {
          toast('Digite o email corretamente');
          setWarning(e => ({ ...e, mail: true }));
          return;
        }
        const { status, error } = await advisorCRUD.update({
          name,
          mail,
          photo: image,
          id: consultantData?.id || 0,
        });
        if (error) throw new Error(error.message.description);
        if (status === 200) {
          onClose();
          toast('Consultor editado com sucesso.');
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  return (
    <Modal
      modalTitle={`${consultantData ? 'Editar' : 'Adicionar'} Consultador`}
      onClose={onClose}
      buttonCallback={() => {
        if (consultantData) {
          editConsultant(name, mail, pass, imageBase64);
          return;
        }
        createConsultant(name, mail, pass, imageBase64);
      }}
      buttonTitle={`${consultantData ? 'Editar' : 'Enviar'}`}>
      <ImageInput
        type="file"
        accept="image/*"
        ref={inputImageRef}
        onChange={event => {
          if (event && event.target?.files?.[0]) {
            const file = event.target.files[0];

            const reader = new FileReader();
            reader.onloadend = () => {
              setImageBase64(reader.result);
            };
            reader.readAsDataURL(file);
            setImg(URL.createObjectURL(event.target.files[0]));
          }
        }}
      />

      <ImageArea onClick={() => inputImageRef.current?.click()}>
        <Image src={img ? img : EmptyImage} />
        <PhotoIcon />
      </ImageArea>

      <TitleSection>Dados Pessoais</TitleSection>
      <InputComponent
        placeholder="Nome"
        type="text"
        value={name}
        setValue={setName}
        customStyle={{ marginBottom: 14 }}
        warning={warning.name}
      />
      <InputComponent
        placeholder="Email"
        type="email"
        value={mail}
        setValue={setMail}
        customStyle={{ marginBottom: 14 }}
        warning={warning.mail}
      />
      {!consultantData && (
        <InputComponent
          placeholder="Senha"
          type="text"
          value={pass}
          setValue={setPass}
          customStyle={{ marginBottom: 0 }}
          warning={warning.pass}
        />
      )}
    </Modal>
  );
};

export default ConsultantModal;
