import styled from 'styled-components';
import Button from '../../../components/button';
import {
  IconAdd,
  IconBackFull,
  IconCheck,
  IconClose,
} from '../../../components/icons';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  height: 100vh;
  /* background-color: ${({ theme }) => theme.colors.grayUltraHigh} */
  background-color: #f9f9f9;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
`;

export const Header = styled.div`
  padding: 25px 0px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayUltraHigh};
  margin-bottom: 45px;
`;

export const HeaderBack = styled.a`
  margin-bottom: 107px;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const AddConsultantButton = styled(Button).attrs({
  customStyle: { marginLeft: 40 },
})``;

export const HeaderTitle = styled.h2`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  font-size: 20px;
  margin: 0px;
`;

export const MatchContainer = styled.div`
  width: 100%;
  max-width: 880px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const MatchDescriptionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 14px 17px;
  border-radius: 9px;
  margin-bottom: 30px;
`;

export const MatchDescriptionText = styled.h5`
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  font-size: 17px;
  color: ${({ theme }) => theme.colors.grayLight};
`;

export const MatchCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0px 17px;
  border-radius: 9px;
  margin-bottom: 40px;
  cursor: pointer;
  transition: transform 350ms ease;
  transform: translateY(0px);

  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: translateY(3px);
    }
  }
`;
export const MatchCardClickArea = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 28px 0;
`;

export const MatchPhoto = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
`;

export const MatchDeleteButton = styled.button`
  display: block;
`;

export const LoadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const PaginationArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
`;

export const NextPageButton = styled(Button).attrs({
  customStyle: { marginLeft: 10, maxWidth: 30 },
})``;

export const PreviousPageButton = styled(Button).attrs({
  customStyle: { maxWidth: 40 },
})``;

// Icons

export const AddIcon = styled(IconAdd).attrs({
  width: 24,
  height: 24,
})`
  fill: none;
  margin-right: 10px;
`;
export const BackFullIcon = styled(IconBackFull).attrs({
  width: 74,
  height: 22,
})`
  fill: none;
`;
export const CheckIcon = styled(IconCheck).attrs({
  width: 22,
  height: 18,
})`
  fill: none;
  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.25);
    }
  }
`;
export const CloseIcon = styled(IconClose).attrs({
  width: 19,
  height: 19,
})`
  fill: none;
  color: red;
  transform: scale(1);
  transition: transform 350ms ease;
  margin-left: 12px;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.25);
    }
  }
`;
