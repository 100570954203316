import axios from 'axios';

import typ, { AxiosManagerProps } from './index.d';
import { CONST_API } from '../../utils/CONSTANTS';

const axiosManager = async <D, E = undefined>({
  method,
  payload,
  url,
  auth
}: typ.axiosManagerProps): Promise<
  | {
      status: number;
      data: D;
      error?: undefined;
    }
  | {
      status: number;
      error: {
        message: {
          title: string;
          description: string;
        };
        data: E;
        stack: any;
      };
      data?: undefined;
    }
> => {
  try {
    let result = await apiSac24({ method, payload, url, auth });
    return { data: result.data, status: result.status };
  } catch (_e) {
    const e = _e as Error;

    if (axios.isAxiosError(e)) {
      let status = Number(
        e.response?.status || e.status || (e.code === 'ERR_NETWORK' && 500),
      );
      return {
        status,
        error: {
          message: await handleMessage(
            status,
            typeof e.response?.data === 'string'
              ? e.response?.data.includes('<body>')
                ? undefined
                : e.response.data
              : e.response?.data?.message,
          ),
          data: e.response?.data,
          stack: { message: String(e?.message), status, stack: e?.stack },
        },
      };
    }
    return {
      status: 600,
      error: {
        message: {
          title: 'Ocorreu um erro interno. (600)',
          description:
            'Tente novamente e, caso o erro persista, entre em contato a Central de Atendimento.',
        },
        data: undefined as E,
        stack: { status: 600, stack: e?.stack, message: e?.message },
      },
    };
  }
};

export { axiosManager };

const apiSac24 = async ({ method, payload, url, auth }: typ.api) => {
  return axios({
    baseURL: `${CONST_API.protocol}${CONST_API.baseUrl}`,
    method,
    url,
    headers: { Authorization: `Bearer ${auth}` },
    timeout: 10000,
    data: payload,
  });
};

const handleMessage = async (code: number, msg?: string) => {
  switch (code) {
    case 603:
      return {
        title: 'Ops... Seu dispositivo está sem conexão com a internet.',
        description:
          'Por favor, verifique sua conexão para restabelecer o serviço.',
      };
    case 400:
      return {
        title: msg || 'Solicitação inválida.',
        description:
          'Tente novamente e, caso o erro persista, entre em contato a Central de Atendimento.',
      };
    case 403:
      return {
        title: msg || 'Credencial informada é inválida.',
        description:
          'Por favor, revise suas credenciais para ativação e, caso o problema persista, entre em contato a Central de Atendimento.',
      };
    case 404:
      return {
        title: 'Não foi possível obter resposta do servidor.',
        description:
          'Tente novamente e, caso o erro persista, entre em contato a Central de Atendimento.',
      };
    default:
      return {
        title: msg || 'Erro interno no servidor.'.concat(` (${code})`),
        description:
          'Tente novamente e, caso o erro persista, entre em contato a Central de Atendimento.',
      };
  }
};
