import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Box = styled.div<{ maxWidth?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : `398px`)};
  padding: 25px;
`;

export const TitleSection = styled.h4`
  margin: 20px 0 15px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 20px;
  text-align: left;
  width: 100%;
`;
