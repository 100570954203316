import styled from 'styled-components';
import CardModal from '../../../modal';
import { IconDocument, IconPhoto } from '../../../icons';
import InputComponent from '../../../input';

export const Modal = styled(CardModal)``;

export const Inputs = styled(InputComponent)``;

export const InputsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DocumentInput = styled.input`
  display: none;
`;

export const DocumentArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const DocumentButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-items: center;
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 5px;
  transition: background-color 350ms ease;
  margin: 0 0 0 10px;

  @media only screen and (min-width: 1024px) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const FileName = styled.p`
  margin: 0px;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  width: 100%;
  text-align: left;
`;

// icones
export const DocumentIcon = styled(IconDocument)`
  fill: none;
  color: ${({ theme }) => theme.colors.white};
`;

export const PhotoIcon = styled(IconPhoto)`
  position: absolute;
  z-index: 2;
  bottom: -8px;
  right: -9px;
  fill: none;
`;
