import React, { LegacyRef } from 'react';

import typ from './index.d';
import { Modal, Separator } from './styles';
import InputComponent from '../../input';
import { toast } from 'react-toastify';
import { ProspectClientCRUD } from '../../../services/requests/prospect-client';
import { formatarPhone } from '../../../utils/convert';

const prospectCrud = new ProspectClientCRUD();

const ProspectModal: React.FC<typ.Props> = ({ onClose, prospect }) => {
  // States
  const [name, setName] = React.useState(prospect?.name || '');
  const [mail, setMail] = React.useState(prospect?.email || '');
  const [type, setType] = React.useState(prospect?.company_type || '');
  const [phone, setPhone] = React.useState(
    (prospect && formatarPhone(prospect?.phone)) || '',
  );

  const [warning, setWarning] = React.useState({
    name: false,
    mail: false,
    type: false,
    phone: false,
  });

  const createGraduation = React.useCallback(
    async (name: string, mail: string, type: string, phone: string) => {
      try {
        if (!name && !mail && !type && !phone) {
          setWarning({
            name: true,
            mail: true,
            type: true,
            phone: true,
          });
          toast('Digite todos os campos.');
          return;
        }
        if (!name) {
          setWarning(e => ({ ...e, name: true }));
          toast('Digite o nome.');
          return;
        }
        if (!mail) {
          setWarning(e => ({ ...e, mail: true }));
          toast('Digite o email.');
          return;
        }
        if (!type) {
          setWarning(e => ({ ...e, type: true }));
          toast('Digite o tipo da empresa.');
          return;
        }
        if (!phone) {
          setWarning(e => ({ ...e, phone: true }));
          toast('Digite o telefone.');
          return;
        }

        if (!mail.includes('@')) {
          toast('Digite o email.');
          return;
        }

        const { data, status, error } = await prospectCrud.create({
          name,
          email: mail,
          company_type: type,
          phone: phone.replace(/\D/g, ''),
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Cliente criado com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const editGraduation = React.useCallback(
    async (name: string, mail: string, type: string, phone: string) => {
      try {
        if (!name && !mail && !type && !phone) {
          setWarning({
            name: true,
            mail: true,
            type: true,
            phone: true,
          });
          toast('Digite todos os campos.');
          return;
        }
        if (!name) {
          setWarning(e => ({ ...e, name: true }));
          toast('Digite o nome.');
          return;
        }
        if (!mail) {
          setWarning(e => ({ ...e, mail: true }));
          toast('Digite o email.');
          return;
        }
        if (!type) {
          setWarning(e => ({ ...e, type: true }));
          toast('Digite o tipo da empresa.');
          return;
        }
        if (!phone) {
          setWarning(e => ({ ...e, phone: true }));
          toast('Digite o telefone.');
          return;
        }

        if (!mail.includes('@')) {
          toast('Digite o email.');
          return;
        }

        const { data, status, error } = await prospectCrud.update({
          name,
          email: mail,
          company_type: type,
          phone: phone.replace(/\D/g, ''),
          id: prospect?.id || 0,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Cliente atualizado com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const formatPhoneNumber = (value: string) => {
    if (value.length > 15) return;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    setPhone(value);
  };
  return (
    <Modal
      modalTitle={`${prospect ? 'Editar' : 'Novo'} cliente`}
      onClose={onClose}
      buttonCallback={() => {
        if (prospect) {
          editGraduation(name, mail, type, phone);
          return;
        }
        createGraduation(name, mail, type, phone);
      }}
      buttonTitle={`${prospect ? 'Editar' : 'Enviar'}`}>
      <InputComponent
        placeholder="Nome"
        type="text"
        value={name}
        setValue={setName}
        warning={warning.name}
      />

      <Separator />

      <InputComponent
        customContainerSize="full"
        value={mail}
        setValue={setMail}
        placeholder={'Email'}
        warning={warning.mail}
      />

      <Separator />

      <InputComponent
        customContainerSize="full"
        value={phone}
        setValue={(e: any) => formatPhoneNumber(e)}
        placeholder={'Celular'}
        warning={warning.phone}
      />

      <Separator />

      <InputComponent
        customContainerSize="full"
        value={type}
        setValue={setType}
        placeholder={'Tipo da empresa'}
        warning={warning.type}
      />
    </Modal>
  );
};

export default ProspectModal;
