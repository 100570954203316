import React, { LegacyRef } from 'react';

import typ from './index.d';
import { Modal, Separator } from './styles';
import InputComponent from '../../input';
import { toast } from 'react-toastify';
import { ContactHistoryCRUD } from '../../../services/requests/contact-history';
import InputSelectComponent from '../../input-select';
import { CONST_SERVICES_TYPE } from '../../../utils/CONSTANTS';
import { ClientHistoryCRUD } from '../../../services/requests/client_history';
import { convertDateToISO } from '../../../utils/convert';

const clientHistoryCrud = new ClientHistoryCRUD();

const ClientHistoryModal: React.FC<typ.Props> = ({
  personalClientId,
  onClose,
}) => {
  // States
  const [date, setDate] = React.useState('');
  const [description, setDescription] = React.useState('');

  const [warning, setWarning] = React.useState({
    date: false,
    description: false,
  });

  const createGraduation = React.useCallback(
    async (date: string, description: string) => {
      try {
        if (!date && !description) {
          setWarning({
            date: true,
            description: true,
          });
          toast('Digite todos os campos.');
          return;
        }
        if (!date) {
          setWarning(e => ({ ...e, name: true }));
          toast('Digite a data do contato.');
          return;
        }
        if (!description) {
          setWarning(e => ({ ...e, description: true }));
          toast('Digite a descrição.');
          return;
        }

        const { data, status, error } = await clientHistoryCrud.create({
          date: convertDateToISO(date),
          description,
          personal_client_id: personalClientId,
        });

        if (error) throw new Error(error.message.description);

        if (status === 200) {
          toast('Contato criado com sucesso.');
          onClose();
        }
      } catch (e) {
        alert(e);
      }
    },
    [],
  );

  const formatDate = (value: string) => {
    if (value.length > 10) return; // Data formatada: DD/MM/AAAA
    value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a primeira barra
    value = value.replace(/(\d{2})(\d)/, '$1/$2'); // Adiciona a segunda barra
    setDate(value);
  };

  return (
    <Modal
      modalTitle={`${false ? 'Editar' : 'Novo'} contato`}
      onClose={onClose}
      buttonCallback={() => {
        // if (contact) {
        //   editContact(name, type, description);
        //   return;
        // }
        createGraduation(date, description);
      }}
      buttonTitle={`${false ? 'Editar' : 'Enviar'}`}>
      <InputComponent
        placeholder="Data do contato"
        type="text"
        value={date}
        setValue={(e: any) => formatDate(e)}
        warning={warning.date}
      />

      <Separator />

      <InputComponent
        customContainerSize="full"
        value={description}
        setValue={setDescription}
        placeholder={'Descrição'}
        warning={warning.description}
        customStyle={{ height: 110 }}
      />
    </Modal>
  );
};

export default ClientHistoryModal;

// const editContact = React.useCallback(
//   async (name: string, type: string, description: string) => {
//     try {
//       if (!name && !type && !description) {
//         setWarning({
//           name: true,
//           type: true,
//           description: true,
//         });
//         toast('Digite todos os campos.');
//         return;
//       }
//       if (!name) {
//         setWarning(e => ({ ...e, name: true }));
//         toast('Digite o nome de contato.');
//         return;
//       }
//       if (!type) {
//         setWarning(e => ({ ...e, type: true }));
//         toast('Digite o tipo de serviço.');
//         return;
//       }
//       if (!description) {
//         setWarning(e => ({ ...e, description: true }));
//         toast('Digite a descrição.');
//         return;
//       }

//       let currentServices: string;

//       if (
//         CONST_SERVICES_TYPE.findIndex(
//           item => item.label.toLowerCase() === type.toLocaleLowerCase(),
//         ) >= 0
//       ) {
//         currentServices =
//           CONST_SERVICES_TYPE[
//             CONST_SERVICES_TYPE.findIndex(
//               item => item.label.toLowerCase() === type.toLocaleLowerCase(),
//             )
//           ].keys;
//       } else {
//         currentServices = type;
//       }

//       const { data, status, error } = await contactHistoryCrud.update({
//         contact_person: name,
//         description,
//         prospect_client_id: Number(prospectClientId),
//         service_type: currentServices,
//         advisor_id: contact?.advisor_relation?.id || undefined,
//         id: contact?.id || 0,
//       });

//       if (error) throw new Error(error.message.description);

//       if (status === 200) {
//         toast('Cliente atualizado com sucesso.');
//         onClose();
//       }
//     } catch (e) {
//       alert(e);
//     }
//   },
//   [],
// );
