import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  height: 100vh;
  /* background-color: ${({ theme }) => theme.colors.grayUltraHigh} */
  background-color: #f9f9f9;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 68px 0px 24px;
  overflow-x: scroll;
`;

export const HeaderImageContainer = styled.div`
  width: 100%;
  border-radius: 17px;
  height: 10px;
  overflow: hidden;
  min-height: 312px;
  position: relative;
  margin-bottom: 50px;
`;
export const HeaderImg = styled.img.attrs({
  src: require('../../../assets/images/home-header.png'),
  className: 'img-cover',
})`
  filter: brightness(0.5);
`;
export const HeaderTitle = styled.h1`
  margin: 0;
  position: absolute;
  left: 50px;
  bottom: 45px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 44px;
  font-family: ${({ theme }) => theme.fontFamily.poppins.bold};
`;

export const CardsArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const Card = styled.a`
  width: calc(100% / 4 - 50px);
  border-radius: 12px;
  height: 258px;
  cursor: pointer;
  position: relative;
  margin-bottom: 50px;
  overflow: hidden;

  img {
    transform: scale(1);
    transition: transform 550ms ease;
    @media only screen and (min-width: 1024px) {
      &:hover {
        transform: scale(1.15);
      }
    }
  }
`;

export const CardRecrutamentoImg = styled.img.attrs({
  src: require('../../../assets/images/home-recrutamento.png'),
  className: 'img-cover',
})`
  filter: brightness(0.5);
`;
export const CardRecolocamentoImg = styled.img.attrs({
  src: require('../../../assets/images/home-recolocacao.jpeg'),
  className: 'img-cover',
})`
  filter: brightness(0.5);
`;
export const CardBancoImg = styled.img.attrs({
  src: require('../../../assets/images/home-banco.png'),
  className: 'img-cover',
})`
  filter: brightness(0.5);
`;
export const CardVagasImg = styled.img.attrs({
  src: require('../../../assets/images/home-vagas.png'),
  className: 'img-cover',
})`
  filter: brightness(0.5);
`;
export const CardConsultoresImg = styled.img.attrs({
  src: require('../../../assets/images/home-consultores.png'),
  className: 'img-cover',
})`
  filter: brightness(0.5);
`;
export const CardTitle = styled.h4`
  margin: 0;
  position: absolute;
  left: 25px;
  bottom: 30px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-family: ${({ theme }) => theme.fontFamily.poppins.bold};
`;
