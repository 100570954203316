import React from 'react';

import typ, { StylesProps } from './index.d';
import {
  Container,
  EyeCloseIcon,
  EyeIcon,
  Input,
  InputLabel,
  // InputSelect,
  ShowPassButton,
} from './styles';
import VancancieModal from '../modais/vancancie';

const InputComponent: React.FC<typ.Props> = ({
  value,
  setValue,
  placeholder,
  customStyle,
  warning,
  type = 'text',
  customContainerSize,
  customStyleLabel,
  selectData,
  isPhone,
  children,
}) => {
  const [showPass, setShowPass] = React.useState(false);

  const status = React.useMemo<StylesProps.inputStatus>(() => {
    if (warning && !value) return 'warning';
    if (value) return 'full';
    if (!value) return 'empty';
    return 'empty';
  }, [value, warning]);

  return (
    <>
      <Container
        sizes={customContainerSize || 'full'}
        onClick={() => selectData && alert('aaaaa')}>
        <InputLabel showLabel={!!value} style={customStyleLabel}>
          {placeholder}
        </InputLabel>
        <Input
          onChange={event => setValue(event.target.value)}
          value={value}
          type={type === 'password' ? (!showPass ? 'password' : 'text') : type}
          placeholder={placeholder || 'Digite aqui...'}
          style={customStyle}
          status={status}
          disabled={selectData ? true : false}
          pattern={isPhone ? "\(\d{2}\)\s*\d{5}-\d{4}" : undefined}
        />
        {children}
        {type === 'password' && (
          <ShowPassButton onClick={() => setShowPass(e => !e)}>
            {showPass ? <EyeCloseIcon /> : <EyeIcon />}
          </ShowPassButton>
        )}
      </Container>
    </>
  );
};

export default InputComponent;
