import styled from 'styled-components';
import CardModal from '../../modal';
import { IconAmount, IconMatchs, IconMoney, IconPin } from '../../icons';

export const Modal = styled(CardModal)``;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const VacanciesCard = styled.a`
  width: calc(100%);
  max-width: 339px;
  border-radius: 13px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 3px dashed #000;
`;

export const VacancieNumber = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: #8e8e8e;
  font-size: 14px;
  display: block;
  margin: 0px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 7px;
  cursor: pointer;
`;

export const VacancieInfoRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const VacancieName = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 22px;
  cursor: pointer;
`;

export const VacancieMatchs = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.blueLight};
  font-size: 14px;
  cursor: pointer;
`;

export const VacancieCategory = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: #959595;
  font-size: 16px;
  margin: 0px 0 21px;
  cursor: pointer;
`;

export const VacancieAmount = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 16px;
  cursor: pointer;
`;

export const VacancieLocality = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 14px;
  cursor: pointer;
`;

export const VacancieSalary = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 14px;
  cursor: pointer;
`;

export const VacancieInfoSvgRow = styled.div`
  display: flex;
  align-items: center;
`;

export const ChosenArea = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const ChosenButton = styled.div<{ accept?: boolean }>`
  border-radius: 3px;
  padding: 4px 16px;
  background-color: ${({ theme, accept }) =>
    accept ? theme.colors.secondary : theme.colors.red};
  font-family: ${({ theme }) => theme.fontFamily.poppins.semiBold};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.25);
    }
  }
`;

export const ChosenLine = styled.div<{ bigger?: boolean }>`
  flex: 1;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.black};
  width: 100%;
`;

export const SearchArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
`;

// Matchs
export const MatchIcon = styled(IconMatchs).attrs({
  width: 29,
  height: 13,
})`
  fill: none;
  margin-right: 5px;
`;
export const AmountIcon = styled(IconAmount).attrs({
  width: 23,
  height: 23,
})`
  fill: none;
  margin-right: 5px;
`;
export const MoneyIcon = styled(IconMoney).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;
export const PinIcon = styled(IconPin).attrs({
  width: 11,
  height: 15,
})`
  fill: none;
  margin-right: 5px;
`;
