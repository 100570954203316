import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import GraduationCRUDProps from './index.d';
('./index.d');

class GraduationCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }
  create({
    graduation_description,
    graduation_name,
    graduation_time,
    graduation_type,
    institution_name,
    id,
    isVancacie,
  }: GraduationCRUDProps.createParams) {
    let data: GraduationCRUDProps.createData = {
      graduation_description,
      graduation_name,
      graduation_time,
      graduation_type,
      institution_name,
    };
    if (isVancacie) data.vacancy_id = id;
    else data.personal_client_id = id;

    console.log(data.personal_client_id)

    return axiosManager<GraduationCRUDProps.createReturn>({
      method: 'post',
      url: `/graduation/create`,
      auth: this.token,
      payload: data,
    });
  }

  read({
    isVancacie,
    id,
    page = 1,
    per_page = 10,
  }: GraduationCRUDProps.readParams) {
    let url = `/graduation/list?page=${page}&per_page=${per_page}`;

    if (isVancacie) url = url + `&vacancy_id=${id}`;
    else url = url + `&personal_client_id=${id}`;

    return axiosManager<GraduationCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }

  update({
    graduation_description,
    graduation_name,
    graduation_time,
    graduation_type,
    institution_name,
    id,
  }: GraduationCRUDProps.updateParams) {

    return axiosManager<GraduationCRUDProps.updateReturn>({
      method: 'put',
      url: `/graduation/update/${id}`,
      auth: this.token,
      payload: {
        graduation_description,
        graduation_name,
        graduation_time,
        graduation_type,
        institution_name,
      },
    });
  }

  delete({ id }: GraduationCRUDProps.deleteParams) {
    return axiosManager<GraduationCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/graduation/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }
}

export { GraduationCRUD };
