import styled from 'styled-components';
import { IconEye, IconEyeClose } from '../icons';
import _, { StylesProps } from './index.d';

export const Container = styled.div<StylesProps.containerSizesProps>`
  width: ${({ sizes }) => {
    switch (sizes) {
      case 'half':
        return 'calc(100% / 2 - 10px)';
      case 'tird':
        return 'calc(100% / 3 - 20px)';
      case 'full':
      default:
        return '100%';
    }
  }};
  position: relative;
`;
export const Input = styled.input<StylesProps.inputStatusProps>`
  width: 100%;
  /* max-width: 348px; */
  border: 1px solid
    ${({ theme, status }) => {
      switch (status) {
        case 'warning':
          return theme.colors.red;
        case 'full':
          return theme.colors.secondary;
        case 'empty':
        default:
          return theme.colors.grayDark;
      }
    }};
  border-radius: 5px;
  padding: 12px 7px;
  @media only screen and (min-width: 480px) {
    padding: 13px 8px;
  }
  @media only screen and (min-width: 991px) {
    padding: 15px 10px;
  }
`;

export const InputLabel = styled.label<{ showLabel?: boolean }>`
  display: block;
  position: absolute;
  width: fit-content;
  z-index: 2;
  top: -14px;
  left: 10px;
  font-size: 13px;
  padding: 3px;
  opacity: ${({ showLabel }) => (showLabel ? 1 : 0)};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.secondary};
  transition: opacity 350ms ease;
`;

// export const InputSelect = styled.div<StylesProps.inputStatusProps>`
//   border: 1px solid
//     ${({ theme, status }) => {
//       switch (status) {
//         case 'warning':
//           return theme.colors.red;
//         case 'full':
//           return theme.colors.secondary;
//         case 'empty':
//         default:
//           return theme.colors.grayDark;
//       }
//     }};
//   padding: 12px 7px;
//   height: 0px;
//   width: 100%;
//   background-color: #f9f9f9;
//   position: absolute;
//   z-index: 9;
//   border-radius: 0 0 5px 5px;
//   height: 50px;
//   width: 100%;
//   bottom: -45px;
//   transition: height 350ms ease;
// `;

export const ShowPassButton = styled.button`
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  height: 48px;
`;

export const EyeIcon = styled(IconEye)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.grayDark};
  fill: none;
`;

export const EyeCloseIcon = styled(IconEyeClose)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.grayDark};
  fill: none;
`;
