import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import TalentPoolCRUDProps from './index.d';
('./index.d');

class TalentPoolCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }
  create({
    budget,
    email,
    local,
    name,
    occupation,
    attachment,
    image,
  }: TalentPoolCRUDProps.createParams) {
    const data = new FormData();
    data.append('email', email);
    data.append('budget', budget);
    data.append('local', local);
    data.append('name', name);
    data.append('occupation', occupation);
    data.append('attachment', attachment);
    data.append('image', image);

    return axiosManager<TalentPoolCRUDProps.createReturn>({
      method: 'post',
      url: `/talent-pool/create`,
      auth: this.token,
      payload: data,
    });
  }

  read({ page = 1, per_page = 10 }: TalentPoolCRUDProps.readParams) {
    let url = `/talent-pool/list?page=${page}&per_page=${per_page}`;

    return axiosManager<TalentPoolCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }

  update({
    budget,
    email,
    local,
    name,
    occupation,
    image,
    attachment,
    id,
  }: TalentPoolCRUDProps.updateParams) {
    const data = new FormData();
    data.append('email', email);
    data.append('budget', budget);
    data.append('local', local);
    data.append('name', name);
    data.append('occupation', occupation);
    data.append('attachment', attachment || '');
    data.append('image', image);

    console.log(data);
    return axiosManager<TalentPoolCRUDProps.updateReturn>({
      method: 'put',
      url: `/talent-pool/update/${id}`,
      auth: this.token,
      payload: data,
    });
  }

  delete({ id }: TalentPoolCRUDProps.deleteParams) {
    return axiosManager<TalentPoolCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/talent-pool/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }

  view({ id }: TalentPoolCRUDProps.viewParams) {
    return axiosManager<TalentPoolCRUDProps.viewReturn>({
      method: 'get',
      url: `/talent-pool/attachment/view/16`,
      auth: this.token,
      payload: null,
    });
  }
}

export { TalentPoolCRUD };
