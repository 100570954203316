import styled from 'styled-components';
import Button from '../../../components/button';
import {
  IconAdd,
  IconAmount,
  IconBackFull,
  IconMatchs,
  IconMoney,
  IconPin,
} from '../../../components/icons';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  height: 100vh;
  /* background-color: ${({ theme }) => theme.colors.grayUltraHigh} */
  background-color: #f9f9f9;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
`;

export const Header = styled.div`
  padding: 25px 0px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayUltraHigh};
  margin-bottom: 30px;
`;

export const HeaderBack = styled.a`
  margin-bottom: 107px;
  cursor: pointer;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const AddVancacieLink = styled.a``;

export const AddConsultantButton = styled(Button).attrs({
  customStyle: { marginLeft: 40 },
})``;

export const HeaderTitle = styled.h2`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  font-size: 20px;
  margin: 0px;
`;

export const VacanciesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  padding-bottom: 50px;
`;

export const VacanciesCard = styled.a`
  width: calc(100% / 3 - 25px);
  border-radius: 13px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 24px 0px #00000014;
  margin-bottom: 40px;
  margin-right: 25px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 350ms ease;

  &:nth-child(3n) {
    margin-right: 0px;
  }
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const VacancieNumber = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: #8e8e8e;
  font-size: 14px;
  display: block;
  margin: 0px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 7px;
  cursor: pointer;
`;

export const VacancieInfoRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const VacancieName = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 22px;
  cursor: pointer;
`;

export const VacancieMatchs = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.blueLight};
  font-size: 14px;
  cursor: pointer;
`;

export const VacancieCategory = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: #959595;
  font-size: 16px;
  margin: 0px 0 21px;
  cursor: pointer;
`;

export const VacancieAmount = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 16px;
  cursor: pointer;
`;

export const VacancieLocality = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 14px;
  cursor: pointer;
`;

export const VacancieSalary = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 14px;
  cursor: pointer;
`;

export const VacancieInfoSvgRow = styled.div`
  display: flex;
  align-items: center;
`;
export const LoadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const PaginationArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
`;

export const NextPageButton = styled(Button).attrs({
  customStyle: { marginLeft: 10, maxWidth: 30 },
})``;

export const PreviousPageButton = styled(Button).attrs({
  customStyle: { maxWidth: 40 },
})``;

// Icons
export const AddIcon = styled(IconAdd).attrs({
  width: 24,
  height: 24,
})`
  fill: none;
  margin-right: 10px;
`;

export const BackFullIcon = styled(IconBackFull).attrs({
  width: 74,
  height: 22,
})`
  color: #8f8f8f;
  fill: none;
  transition: color 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondaryDark};
    }
  }
`;

export const MatchIcon = styled(IconMatchs).attrs({
  width: 29,
  height: 13,
})`
  fill: none;
  margin-right: 5px;
`;
export const AmountIcon = styled(IconAmount).attrs({
  width: 23,
  height: 23,
})`
  fill: none;
  margin-right: 5px;
`;
export const MoneyIcon = styled(IconMoney).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;
export const PinIcon = styled(IconPin).attrs({
  width: 11,
  height: 15,
})`
  fill: none;
  margin-right: 5px;
`;
