import { Reducer } from '@reduxjs/toolkit';
import { reducers } from '../reducers';
import { RootState } from '..';

const reducerManager: Reducer = (state: RootState, action) => {
  if (action.type === 'session/signOut') {
    return reducers({}, action);
  }
  return reducers(state, action);
};

export { reducerManager };
