import styled from 'styled-components';
import {
  IconDocument,
  IconEdit,
  IconMail,
  IconMoney,
  IconPin,
  IconTrash,
} from '../../icons';

export const Card = styled.div`
  width: 294px;
  border-radius: 13px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 24px 0px #00000014;
  margin-bottom: 40px;
  margin-right: 40px;

  &:nth-child(4n) {
    margin-right: 0px;
  }
  cursor: pointer;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const CardRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CardRowForSvg = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #ececec;
  margin-bottom: 8px;
`;

export const DocumentArea = styled.div`
  width: 29px;
  height: 29px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;

export const Button = styled.button`
  padding: 0px;
`;
export const Link = styled.a`
  padding: 0px;
`;

export const Name = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 14px;
`;
export const Job = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.grayLight};
  font-size: 13px;
`;

export const UserImg = styled.img`
  width: 47px;
  height: 47px;
  border-radius: 25px;
  margin-right: 8px;
`;
export const ConsultantImg = styled.img`
  width: 33px;
  height: 33px;
  border-radius: 25px;
`;

export const Mail = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 13px;
`;

export const Locality = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 14px;
`;

export const Salary = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 14px;
`;

export const DocumentIcon = styled(IconDocument).attrs({
  width: 12,
  height: 14,
})`
  fill: none;
  color: ${({ theme }) => theme.colors.white};
`;

export const TrashIcon = styled(IconTrash).attrs({
  width: 20,
  height: 24,
})`
  fill: none;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;
export const MailIcon = styled(IconMail).attrs({
  width: 18,
  height: 14,
})`
  fill: none;
  margin-right: 5px;
`;

export const EditIcon = styled(IconEdit).attrs({
  width: 24,
  height: 24,
})`
  fill: none;
  margin-right: 20px;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;

export const MoneyIcon = styled(IconMoney).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

export const PinIcon = styled(IconPin).attrs({
  width: 11,
  height: 15,
})`
  fill: none;
  margin-right: 5px;
`;
