import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import MatchCRUDProps from './index.d';

class MatchCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }
  create({
    advisor_id,
    personal_client_id,
    vacancy_id,
  }: MatchCRUDProps.createParams) {
    return axiosManager<MatchCRUDProps.createReturn>({
      method: 'post',
      url: `/vacancy-match/create`,
      auth: this.token,
      payload: {
        advisor_id,
        personal_client_id,
        vacancy_id,
        authorized: true,
      },
    });
  }

  read({ page = 1, per_page = 10, vacancy_id }: MatchCRUDProps.readParams) {
    let url = `/vacancy-match/list?page=${page}&per_page=${per_page}`;
    if (vacancy_id) url = url + `&vacancy_id=${vacancy_id}`;

    return axiosManager<MatchCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }

  update({
    advisor_id,
    authorized,
    personal_client_id,
    vacancy_id,
    match_id,
  }: MatchCRUDProps.updateParams) {
    return axiosManager<MatchCRUDProps.updateReturn>({
      method: 'put',
      url: `/vacancy-match/update/${match_id}`,
      auth: this.token,
      payload: {
        advisor_id,
        authorized,
        personal_client_id,
        vacancy_id,
      },
    });
  }

  delete({ id }: MatchCRUDProps.deleteParams) {
    return axiosManager<MatchCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/vacancy-match/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }
}

export { MatchCRUD };
