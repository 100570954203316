import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import AdvisorCRUDProps from './index.d';

class AdvisorCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }

  create({ mail, name, pass, photo }: AdvisorCRUDProps.createParams) {
    return axiosManager<AdvisorCRUDProps.createReturn>({
      method: 'post',
      url: `/advisor/create`,
      auth: this.token,
      payload: { email: mail, name, password: pass, image: photo },
    });
  }
  read({ page, per_page = 20, id }: AdvisorCRUDProps.readParams) {
    let url = `/advisor/list?per_page=${per_page}`;
    if (id) url = url + `&id=${id}`;
    return axiosManager<AdvisorCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }
  update({ mail, name, id, photo }: AdvisorCRUDProps.updateParams) {
    return axiosManager<AdvisorCRUDProps.updateReturn>({
      method: 'put',
      url: `/advisor/update/${id}`,
      auth: this.token,
      payload: { email: mail, name, image: photo },
    });
  }
  delete({ id }: AdvisorCRUDProps.deleteParams) {
    return axiosManager<AdvisorCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/advisor/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }
}

export { AdvisorCRUD };
