import React from 'react';
import {
  AddConsultantButton,
  AddIcon,
  BackFullIcon,
  Container,
  Content,
  Header,
  HeaderActions,
  HeaderBack,
  HeaderInfo,
  HeaderTitle,
  LoadRow,
  NextPageButton,
  PaginationArea,
  PreviousPageButton,
  TalentBankContainer,
} from './styles';
import MenuBar from '../../../components/menu-bar';
import ResponsiveContainer from '../../../components/container';
import Search from '../../../components/search';
import CardUserInfo from '../../../components/cards/user-info';
import CandidateModal from '../../../components/modais/candidate';
import CardUserJob from '../../../components/cards/user-job';
import WarnModal from '../../../components/modais/warn';
import TalentPoolCRUDProps, {
  TalentPoolData,
} from '../../../services/requests/talent-pool/index.d';
import { TalentPoolCRUD } from '../../../services/requests/talent-pool';
import Load from '../../../components/load';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';

const talentPoolCRUD = new TalentPoolCRUD();

const TalentPoolPage: React.FC = () => {
  const { colors } = useTheme();

  // Ref
  const currentPage = React.useRef(1);

  // States
  const [search, setSearch] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentTalent, setCurrentTalent] = React.useState<TalentPoolData>();
  const [showWarn, setShowWarn] = React.useState(false);
  const [talentsPool, setTalentsPool] =
    React.useState<TalentPoolCRUDProps.readReturn>();

  // Callbacks
  const getTalentsPools = React.useCallback(async (page?: number) => {
    try {
      setLoading(true);
      currentPage.current = page || 1;
      const { data, status, error } = await talentPoolCRUD.read({
        page,
        per_page: 12,
      });

      if (error) {
        throw new Error(error.message.description);
      }

      if (status === 200 && data) {
        setTalentsPool(data);
      }
    } catch (e) {
      alert(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteTalentPool = React.useCallback(async (id: number) => {
    try {
      const { data, status, error } = await talentPoolCRUD.delete({ id });

      if (error) throw new Error('Erro ao deletar usuario.');

      if (status === 200) {
        toast('Cliente deletado com sucesso.');
        getTalentsPools();
      }
    } catch (e) {
      toast(e as String);
    }
  }, []);

  const currentData = React.useMemo(() => {
    if (search) {
      let currentFilter = search.toLocaleLowerCase();
      return talentsPool?.TalentPool?.filter(e =>
        e.name.toLowerCase().includes(currentFilter),
      );
    }
    return talentsPool?.TalentPool;
  }, [talentsPool, search]);

  // Effect
  React.useEffect(() => {
    getTalentsPools();
  }, []);

  return (
    <Container>
      <MenuBar currentPage="Talentos" />
      <Content>
        <ResponsiveContainer>
          <Header>
            <HeaderBack href="/">
              <BackFullIcon />
            </HeaderBack>
            <HeaderInfo>
              <HeaderTitle>Banco de talentos</HeaderTitle>
              <HeaderActions>
                <Search
                  value={search}
                  setValue={setSearch}
                  placeholder="Pesquisar clientes"
                />
                <AddConsultantButton
                  title="Adicionar cliente"
                  iconRight={AddIcon}
                  callback={() => setShowModal(true)}
                />
              </HeaderActions>
            </HeaderInfo>
          </Header>
          <TalentBankContainer>
            {loading ? (
              <LoadRow>
                <Load
                  customStyle={{
                    color: colors.secondaryDark,
                  }}
                  size="extraLarge"
                />
              </LoadRow>
            ) : (
              <>
                {currentData && currentData?.length > 0 ? (
                  currentData?.map((e, i) => {
                    return (
                      <CardUserInfo
                        talentPool={e}
                        key={i}
                        deleteCallback={() => {
                          setCurrentTalent(e);
                          setShowWarn(true);
                        }}
                        editCallback={() => {
                          setCurrentTalent(e);
                          setShowModal(true);
                        }}
                      />
                    );
                  })
                ) : (
                  <HeaderTitle>Nenhum talento encontrado...</HeaderTitle>
                )}
                <PaginationArea>
                  {talentsPool?.has_prev && (
                    <PreviousPageButton
                      title="Voltar Página"
                      callback={() => getTalentsPools(currentPage.current - 1)}
                    />
                  )}
                  {talentsPool?.has_next && (
                    <NextPageButton
                      title="Proxima Página"
                      callback={() => getTalentsPools(currentPage.current + 1)}
                    />
                  )}
                </PaginationArea>
              </>
            )}
          </TalentBankContainer>
        </ResponsiveContainer>
      </Content>
      {showModal && (
        <CandidateModal
          onClose={() => {
            getTalentsPools();
            setShowModal(false);
            setCurrentTalent(undefined);
          }}
          talentPool={currentTalent}
        />
      )}
      {showWarn && currentTalent && (
        <WarnModal
          title="Remover talento"
          description={`Certeza que quer remover o ${currentTalent.name} do seu banco de talentos?`}
          confirmCallback={() => {
            handleDeleteTalentPool(currentTalent?.id || 0);
            setShowWarn(false);
          }}
          declineCallback={() => {
            setCurrentTalent(undefined);
            setShowWarn(false);
          }}
        />
      )}
    </Container>
  );
};

export default TalentPoolPage;
