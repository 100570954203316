import { axiosManager } from '../../axios-manager';
import LoginProps from './index.d';

class LoginCRUD {
  login({ email, password }: LoginProps.requestParams) {
    return axiosManager<LoginProps.returnParams>({
      method: 'post',
      url: `/sign-in`,
      auth: '',
      payload: {
        email,
        password,
      },
    });
  }
}

export { LoginCRUD };
