import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import VancancieCRUDProps from './index.d';

class VancancieCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }

  create({
    name,
    benefits,
    budget,
    contact_name,
    contact_phone,
    contact_role,
    email,
    employment_type,
    key_words,
    level,
    occupation,
    perfil,
    quantity,
    sexual_gender,
    workplace,
    priority,
    corporate_client_id,
  }: VancancieCRUDProps.createParams) {
    return axiosManager<VancancieCRUDProps.createReturn>({
      method: 'post',
      url: `/vacancy/create`,
      auth: this.token,
      payload: {
        name,
        quantity,
        level,
        occupation,
        budget,
        sexual_gender,
        contact_name,
        contact_role,
        contact_phone,
        email,
        employment_type,
        benefits,
        perfil,
        workplace,
        key_words,
        corporate_client_id,
      },
    });
  }
  read({ page = 1, per_page = 10, id, name }: VancancieCRUDProps.readParams) {
    let url = `/vacancy/list?page=${page}&per_page=${per_page}`;
    if (id) url = url + `&id=${id}`;
    if (name) url = url + `&name=${name}`;
    return axiosManager<VancancieCRUDProps.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }
  update({
    name,
    benefits,
    budget,
    contact_name,
    contact_phone,
    contact_role,
    email,
    employment_type,
    key_words,
    level,
    occupation,
    perfil,
    quantity,
    sexual_gender,
    workplace,
    id,
  }: VancancieCRUDProps.updateParams) {
    console.log({
      name,
      benefits,
      budget,
      contact_name,
      contact_phone,
      contact_role,
      email,
      employment_type,
      key_words,
      level,
      occupation,
      perfil,
      quantity,
      sexual_gender,
      workplace,
      id,
    });
    return axiosManager<VancancieCRUDProps.updateReturn>({
      method: 'put',
      url: `/vacancy/update/${id}`,
      auth: this.token,
      payload: {
        name,
        quantity,
        level,
        occupation,
        budget,
        sexual_gender,
        contact_name,
        contact_role,
        contact_phone,
        email,
        employment_type,
        benefits,
        perfil,
        workplace,
        key_words,
      },
    });
  }
  delete({ id }: VancancieCRUDProps.deleteParams) {
    return axiosManager<VancancieCRUDProps.deleteReturn>({
      method: 'delete',
      url: `/vacancy/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }
}

export { VancancieCRUD };
