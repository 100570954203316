import React from 'react';

import typ from './index.d';
import {
  Box,
  Button,
  ButtonsArea,
  Container,
  Description,
  Title,
  WarnIcon,
} from './styled';

const WarnModal: React.FC<typ.Props> = ({
  declineCallback,
  confirmCallback,
  description,
  title,
}) => {
  return (
    <Container>
      <Box>
        <WarnIcon />
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ButtonsArea>
          <Button onClick={declineCallback}>Não, manter.</Button>
          <Button accept onClick={confirmCallback}>
            Sim, remover.
          </Button>
        </ButtonsArea>
      </Box>
    </Container>
  );
};

export default WarnModal;
