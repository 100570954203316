import React from 'react';

import typ, { StylesProps } from './index.d';
import { Container, InputLabel, Select } from '../input-select/styles';

const InputSelectComponent: React.FC<typ.Props> = ({
  value,
  setValue,
  placeholder,
  customStyle,
  warning,
  customContainerSize,
  customStyleLabel,
  selectData,
}) => {
  const [showPass, setShowPass] = React.useState(false);

  const status = React.useMemo<StylesProps.inputStatus>(() => {
    if (warning && !value) return 'warning';
    if (value) return 'full';
    if (!value) return 'empty';
    return 'empty';
  }, [value, warning]);

  // Função que será chamada quando o usuário selecionar uma opção
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value); // Atualiza o estado com o valor selecionado
  };

  return (
    <Container sizes={customContainerSize || 'full'}>
      <InputLabel showLabel={!!value} style={customStyleLabel}>
        {placeholder}
      </InputLabel>
      <Select
        style={customStyle}
        status={status}
        id="opcoes"
        name="opcoes"
        value={value}
        onChange={handleSelectChange}
        defaultValue={value || placeholder}>
        <option value="" style={{ display: 'none' }}>
          {value || placeholder}
        </option>
        {selectData?.map((e, i) => <option key={i} value={e.keys}>{e.label}</option>)}
      </Select>
    </Container>
  );
};

export default InputSelectComponent;
