import Cookies from 'js-cookie';
import { axiosManager } from '../../axios-manager';
import CorporateClient from './index.d';

class CorporateClientCRUD {
  private readonly token;

  constructor() {
    this.token = Cookies.get('token') || '';
  }
  create({
    name,
    email,
    local,
    description,
    cnpj,
    contract_validate,
    file,
  }: CorporateClient.createParams) {
    return axiosManager<CorporateClient.createReturn>({
      method: 'post',
      url: `/corporate-client/create`,
      auth: this.token,
      payload: {
        cnpj,
        contract_validate,
        description,
        email,
        local,
        name,
        file,
      },
    });
  }

  read({ page = 1, per_page = 10 }: CorporateClient.readParams) {
    let url = `/corporate-client/list?page=${page}&per_page=${per_page}`;

    return axiosManager<CorporateClient.readReturn>({
      method: 'get',
      url,
      auth: this.token,
      payload: null,
    });
  }

  update({
    name,
    email,
    local,
    description,
    cnpj,
    contract_validate,
    file,
    id,
  }: CorporateClient.updateParams) {
    return axiosManager<CorporateClient.updateReturn>({
      method: 'put',
      url: `/corporate-client/update/${id}`,
      auth: this.token,
      payload: {
        cnpj,
        contract_validate,
        description,
        email,
        local,
        name,
        file,
      },
    });
  }

  delete({ id }: CorporateClient.deleteParams) {
    return axiosManager<CorporateClient.deleteReturn>({
      method: 'delete',
      url: `/corporate-client/delete/${id}`,
      auth: this.token,
      payload: null,
    });
  }
}

export { CorporateClientCRUD };
