const theme = {
  colors: {
    primary: '#7B097E',
    primaryDark: '#7D0C7F',
    primaryLight: '#7F2C7C',
    secondary: '#8DBBA2',
    secondaryDark: '#5FBA99',
    text: '#494949',
    textSecondary: '#606060',
    grayDark: '#818181',
    gray: '#4D4D4D',
    grayLight: '#8A8A8A',
    grayMediumLight: '#959595',
    grayUltraHigh: '#CCCCCC',
    blue: '#5278FFEB',
    blueLight: '#63A1F6',
    white: '#fff',
    black: '#000',
    red: '#EB4D4B',
    redUltraLight: '#E00D0D2E',
  },
  fontFamily: {
    poppins: {
      regular: 'Poppins Regular',
      semiBold: 'Poppins Semi Bold',
      bold: 'Poppins Bold',
    },
    inter: {
      regular: 'Inter Regular',
      medium: 'Inter Medium',
      semiBold: 'Inter Semi Bold',
      bold: 'Inter Bold',
    },
  },
};
export { theme };
export type ThemeType = typeof theme;
