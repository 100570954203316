import styled from 'styled-components';
import CardModal from '../../modal';
import { IconDocument, IconPhoto } from '../../icons';

export const Modal = styled(CardModal)``;

export const Separator = styled.div`
  padding: 7px;
  width: 100%;
`;

// icones
export const DocumentIcon = styled(IconDocument)`
  fill: none;
`;

export const PhotoIcon = styled(IconPhoto)`
  position: absolute;
  z-index: 2;
  bottom: -8px;
  right: -9px;
  fill: none;
`;
