import React from 'react';

import { Box, Container } from './styles';
import Button from '../button';
import typ from './index.d';
import TitleRow from './title-row';

const CardModal: React.FC<typ.Props> = ({
  modalTitle,
  buttonTitle,
  buttonCallback,
  children,
  onClose,
  hideButton,
  maxWidth,
}) => {
  return (
    <Container>
      <Box maxWidth={maxWidth}>
        <TitleRow modalTitle={modalTitle} onClose={onClose} />
        {children}
        {!hideButton && (
          <Button
            title={buttonTitle || 'Enviar'}
            callback={buttonCallback}
            customStyle={{ marginTop: 20 }}
          />
        )}
      </Box>
    </Container>
  );
};

export default CardModal;
