import React from 'react';
import { Input, InputContainer, SearchIcon } from './styles';

import typ from './index.d';

const Search: React.FC<typ.Props> = ({
  placeholder,
  setValue,
  value,
  customStyle,
}) => {
  return (
    <InputContainer style={customStyle}>
      <SearchIcon />
      <Input
        placeholder={placeholder}
        onChange={event => setValue(event.target.value)}
        value={value}
        type="text"
      />
    </InputContainer>
  );
};

export default Search;
