import {createSlice} from '@reduxjs/toolkit';
import typ from './index.d';
const initialState: typ.Props = {
  currentWarning: '',
};

export const warningBar = createSlice({
  name: 'warningBar',
  initialState,
  reducers: {
    reset: () => initialState,
    setWarn: (state, {payload}) => {
      state.currentWarning = payload;
    },
  },
});

export const warningBarActions = warningBar.actions;
export default warningBar.reducer;
