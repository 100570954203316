import styled from 'styled-components';
import CardModal from '../../../modal';
import {
  IconAmount,
  IconCalendar,
  IconCpf,
  IconDocument,
  IconEdit,
  IconMail,
  IconMoney,
  IconPhoto,
  IconPin,
  IconTrash,
} from '../../../icons';
import ButtonComp from '../../../../components/button';
export const Modal = styled(CardModal)``;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
`;
export const Button = styled.button``;
export const DocumentButton = styled.button`
  border-radius: 5px;
  padding: 10px;
  /* background-color: ${({ theme }) => theme.colors.primary}; */
  background-color: ${({ theme }) => theme.colors.white};
`;

export const InfoArea = styled.div`
  width: calc(100%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Name = styled.h3`
  margin: 0px 0px 6px;
  font-size: 34px;
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  line-height: 36.57px;
`;

export const Occupation = styled.h3`
  margin: 0px 0px 6px;
  font-size: 24px;
  color: #959595;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
`;

export const Description = styled.h3`
  margin: 0px 0px 20px;
  font-size: 13px;
  color: #525252;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  width: 80%;
`;

export const RowTitle = styled.h3`
  margin: 0px 0px 6px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
`;

export const SeeMoreLink = styled.a`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.inter.bold};
  text-decoration: underline;
  transition: color 350ms ease;
  margin: 0 0 15px 10px;

  @media only screen and (min-width: 1024px) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;
export const InfoSvgRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const ContactSvgRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const Amount = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 16px;
`;

export const Salary = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: ${({ theme }) => theme.colors.secondaryDark};
  font-size: 16px;
`;

export const Contact = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 16px;
`;

export const KeyWordsArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 18px 0 0;
`;

export const KeyWord = styled.label`
  display: block;
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter.regular};
  color: #3a3577;
  font-size: 15px;
  background-color: #dfddff;
  border: 1px solid #8075ff;
  border-radius: 4px;
  padding: 3px 9px;
  margin: 0 3px 5px;
`;

// icones
export const DocumentIcon = styled(IconDocument)`
  fill: none;
  color: ${({ theme }) => theme.colors.white};
`;

export const PhotoIcon = styled(IconPhoto)`
  position: absolute;
  z-index: 2;
  bottom: -8px;
  right: -9px;
  fill: none;
`;

export const TrashIcon = styled(IconTrash).attrs({
  width: 20,
  height: 24,
})`
  fill: none;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;

export const EditIcon = styled(IconEdit).attrs({
  width: 24,
  height: 24,
})`
  fill: none;
  margin-right: 20px;

  transform: scale(1);
  transition: transform 350ms ease;
  @media only screen and (min-width: 1024px) {
    &:hover {
      transform: scale(1.15);
    }
  }
`;
export const CreateVacancyButton = styled(ButtonComp).attrs({
  customStyle: { minWidth: 0 },
})``;

export const AmountIcon = styled(IconAmount).attrs({
  width: 23,
  height: 23,
})`
  fill: none;
  margin-right: 5px;
`;

export const MoneyIcon = styled(IconMoney).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

export const PinIcon = styled(IconPin).attrs({
  width: 16,
  height: 21,
})`
  fill: none;
  margin-right: 5px;
`;

export const MailIcon = styled(IconMail).attrs({
  width: 22,
  height: 17,
})`
  fill: none;
  margin-right: 5px;
`;

export const CpfIcon = styled(IconCpf).attrs({
  width: 16,
  height: 22,
})`
  fill: none;
  margin-right: 5px;
`;
export const CalendarIcon = styled(IconCalendar).attrs({
  width: 16,
  height: 22,
})`
  fill: none;
  margin-right: 5px;
`;

// Calendar indicator
export const CalendarRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const ContainerIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
`;
export const Circle = styled.div`
  background-color: #8f99ae;
  height: 11px;
  width: 11px;
  border-radius: 11px;
`;
export const Line = styled.div`
  width: 43px;
  background-color: #8f99ae;
  height: 3px;
`;
